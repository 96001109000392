import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loadingStore", {
    state: () => ({
        isLoading: false,
    }),
    actions: {
        setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
        },
    },
    });