import { defineStore } from "pinia";
import { ref } from "vue";
import { Document } from "@/models/opal";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import axios from "axios";
import { useStore } from "vuex";

interface ApiResponse {
  success: boolean;
  results?: string;
  errors?: string[];
}

export const useDocumentsTabStore = defineStore("documentsTab", () => {
  const documents = ref<Array<any>>([]);
  const documentsList = ref<Array<any>>([]);
  const currentlyEditingDocIds = ref<Array<number>>([]);
  const currentlyEditingDocOwners = ref<Array<number>>([]);
  const documentPdfModalOpen = ref<boolean>(false);

  const uploadedDocuments = ref<Array<any>>([]);
  const generatedDocuments = ref<Array<any>>([]);
  const manualGenerateDocList = ref<Array<any>>([]);

  const store = useStore()



  const applicationStore = useApplicationStore();

  

  function isEditDisabled(document: Document) {
    return document.documentStatusTypeName === "Accepted";
  }

  function updateDocument(
    documentId: number,
    updatedDocument: Partial<Document>
  ) {
    const index = documents.value.findIndex(
      (item) => item.documentId === documentId
    );

    if (index !== -1) {
      documents.value[index] = {
        ...documents.value[index],
        ...updatedDocument,
      };
    }
  }

  function toggleEditMode(documentId: number, applicantId: number) {
    const docIndex = currentlyEditingDocIds.value.indexOf(documentId);
    const ownerIndex = currentlyEditingDocOwners.value.indexOf(applicantId);

    if (docIndex === -1 && ownerIndex === -1) {
      currentlyEditingDocIds.value.push(documentId);
      currentlyEditingDocOwners.value.push(applicantId);
    } else {
      untoggleEditMode(documentId);
    }
  }

  function untoggleEditMode(documentId: number) {
    const index = currentlyEditingDocIds.value.indexOf(documentId);
    if (index !== -1) {
      currentlyEditingDocIds.value.splice(index, 1);
      currentlyEditingDocOwners.value.splice(index, 1);
    }
  }

  async function getDocumentsList() {
    try {
      const applicationId = applicationStore.applicationId;
      const response = await axios.get(`/api/documents/${applicationId}/list`);
      const data = response.data.results;
      documentsList.value = data.documents;
      uploadedDocuments.value = data.uploadedDocuments;
      generatedDocuments.value = data.generatedDocuments;
      manualGenerateDocList.value = data.manualGenerateDocList;
    } catch (error) {
      console.error(`Error getting documents list: ${error}`);
      throw error;
    }
  }

async function generateManualDocument(
  documentTypeId: number,
  applicantId: number,
  comment: string
): Promise<ApiResponse> {
  try {
    const payload = {
      DocumentTypeId: documentTypeId,
      ApplicationId: applicationStore.applicationId,
      ApplicantId: applicantId,
      Comment: comment,
      UserId: store.state.oidcStore.user.sub,
      UserName: store.state.oidcStore.user.name,
    };

    const response = await axios.post(`/api/documents/manualdocgen`, payload);

    // Check the structure of the response data for errors and results
    if (response.status === 200 && response.data.success === true) {
      return {
        success: true,
        results: response.data.results || "Document generated successfully",
      };
    } else {
      // Handle cases where `success: false` but the status is 200
      return {
        success: false,
        results: response.data.results || "Failed",
        errors: response.data.errors || [
          "Unknown error occurred while generating the document",
        ],
      };
    }
  } catch (error: any) {
    console.error("Error in generateManualDocument:", error);
    return {
      success: false,
      errors: [
        error.message || "Network error occurred while generating the document",
      ],
    };
  }
}



  return {
    documents,
    documentsList,
    uploadedDocuments,
    generatedDocuments,
    manualGenerateDocList,
    getDocumentsList,
    updateDocument,
    isEditDisabled,
    currentlyEditingDocIds,
    currentlyEditingDocOwners,
    toggleEditMode,
    untoggleEditMode,
    documentPdfModalOpen,
    generateManualDocument,
  };
});
