<template>
  <div v-auto-animate="$animate" class="xcommentbox-wrapper">
    <div class="xcommentbox" v-if="!showComments" @click="toggleComments()">
      <i class="icon-pencil-alt"></i> Write a comment...
    </div>
    <div class="xcommentbox-expand" @click="toggleComments()">
      <div v-if="!showComments">
        <span>{{ commentData.length }}</span> Comment<span
          v-if="commentData.length !== 1"
          >s</span
        >
        <i class="icon-down-open-big"></i>
      </div>
      <div v-else>Hide Comments <i class="icon-up-open-big"></i></div>
    </div>
  </div>
  <div class="clear"></div>
  <div v-auto-animate="$animate">
    <div v-if="showComments" class="xcommentbox-main">
      <form
        @submit.prevent="addLoanComment()"
        style="padding-bottom: 20px"
        v-auto-animate="$animate"
      >
        <h3 class="smallcapitals">
          <i class="icon-pencil-alt" style="margin-right: 5px"></i> Write a
          Comment:
        </h3>
        <textarea
          v-model="newComment"
          ref="focusComment"
          maxlength="4000"
        ></textarea>
        <input
          type="submit"
          v-if="newComment.length > 0"
          style="margin-top: 5px"
        />
      </form>
      <h3 class="smallcapitals">
        Comments (<span>{{ commentData.length }}</span
        >)
      </h3>
      <div
        class="xcomments"
        v-auto-animate="$animate"
        v-if="commentData"
        style="padding-bottom: 20px"
      >
        <div
          class="xcommentbox-main-item"
          v-for="(comment, index) in sortedComments"
          :key="index"
        >
          <div
            class="xusericon"
            data-size="medium"
            style="float: left; display: block; margin-right: 10px"
          >
            <div class="xusericon-picture">
              {{ getInitials(comment.userDisplayName) }}
            </div>
          </div>
          <div class="xcomments-content">
            <h4>{{ comment.userDisplayName || "No Name" }}<span v-if="isSchoolPortalComment(comment)" class="pill-green"
              >Draw Comment</span
            ></h4>
            <p>{{ comment.comment }}</p>
            <h5>
              {{
                moment(comment.createdAtUtc)
                  .tz(localTimezone)
                  .format("MMMM D, YYYY [at] h:mm A z")
              }}
            </h5>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, ref, nextTick, computed } from "vue";
import axios from "axios";
import store from "../store";
import functions from "@/use/functions.js";
import moment from "moment";

const props = defineProps(["type", "id"]);
const { type, id } = toRefs(props);

let commentData = ref([]);
let showComments = ref(false);
let newComment = ref("");
let focusComment = ref(null);

const sortedComments = computed(() => {
  return [...commentData.value].sort(
    (a, b) => new Date(b.createdAtUtc) - new Date(a.createdAtUtc)
  );
});

async function getCommentsById() {
  let requestUrl = `/api/applications/${id.value}/comments/`;
  if (type.value === "loan") {
    requestUrl = `/api/comments/loan/${id.value}`;
  }
  try {
    const response = await axios.get(requestUrl);
    if (Array.isArray(response.data) && response.data.length >= 1) {
      response.data.forEach((comment) => {
        const parser = new DOMParser();
        const decodedComment = parser.parseFromString(
          comment.comment,
          "text/html"
        ).body.textContent;
        comment.comment = decodedComment;
      });
      commentData.value = response.data.sort(
        (a, b) => new Date(b.createdAtUtc) - new Date(a.createdAtUtc)
      );
    } else {
      commentData.value = [];
    }
  } catch (error) {
    console.log(`API Error: ${requestUrl}`, error);
    functions.openModal("Error: Comments could not be loaded!");
  }
}

async function addLoanComment() {
  let postUrl = `/api/comments/loan`;
  let jsonData = {};

  if (type.value === "loan") {
    jsonData = {
      loanId: id.value,
      comment: newComment.value,
      userDisplayName: `${store.state.oidcStore.user.given_name} ${store.state.oidcStore.user.family_name}`,
    };
    postUrl = `/api/comments/loan`;
  }

  if (!jsonData.comment) {
    functions.openModal("Please enter a comment before submitting.");
    return;
  }

  try {
    const response = await axios.post(postUrl, jsonData);
    console.log("Response:", response);

    functions.openModal("Your comment has been added!");

    commentData.value.unshift(jsonData);
    newComment.value = "";
  } catch (error) {
    console.log(`API Error: ${postUrl}`, error);
    functions.openModal("Error: Comment was not saved!");
  }
}

function isSchoolPortalComment(comment) {
  return comment.commentType === 2;
}

function toggleComments() {
  showComments.value = !showComments.value;
  if (showComments.value) {
    nextTick(() => {
      setTimeout(() => {
        if (focusComment.value) {
          focusComment.value.focus();
        }
      }, 500);
    });
  }
}

function getInitials(input) {
  if (input) {
    return input
      .split(" ")
      .map((name) => name[0])
      .join("")
      .substr(0, 2);
  } else {
    return "NN";
  }
}

getCommentsById(props.id);
addLoanComment(props.id);
</script>

<style scoped>
.pill-green {
  background-color: #32cd32;
  color: white;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
  font-size: 10px;
}
</style>
