/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FormField, FormSchema, FieldSchema } from "../models/form";

export default function useValidation() {
    function validateStipulationOverrideText(overrideText: string){
       return !overrideText.includes("{{") || !overrideText.includes("}}")
    }

    function getErrorMessage(field: FormField | FieldSchema) {
        if (field.errorMessage) {
            return field.errorMessage;
        }

        switch (field.type) {
            case "email":
                return "Please provide a valid email address.";
            case "compare":
                return `Compared values do not match.`;
            case "phone":
                return "Please provide a valid phone number.";
            case "nationalIdNumber":
                return getNationalIdNumberErrorMessage(field);
            case "zipcode":
                return `Please provide a valid zip code.`;
            default:
                return field.label
                    ? `${field.label} is required.`
                    : "Please provide a value.";
        }
    }

    function getNationalIdNumberErrorMessage(field: FormField | FieldSchema): string
    {
        if (field.label == "Social Security Number") {
            return "Please provide a valid social security number.";
        } else {
            return "Please provide a valid ITIN.";
        }
    }

    async function validateAddressFields(
        schema: FormField | FieldSchema,
        value: any
    ) {
        schema.touched = true;
        // Reset the error messages
        schema.errors = [];

        // character limits: handled by maxlength input property

        if (schema.name === "addrLine1" || schema.name === "address1") {
            // Only allow standard A-Z a-z 0-9 values, single space, and - '
            const specialCharacterCheck = /[^A-Za-z0-9-' ]/.test(value);
            if (specialCharacterCheck === true) {
                schema.errors.push("Must not contain special characters.");
            }
        }

        if (schema.name === "city") {
            // Only allow standard A-Z a-z values, and single spaces
            const invalidCharCheck = /[^A-Za-z-' ]/.test(value);
            if (invalidCharCheck === true) {
                schema.errors.push("Sorry, only letters (a-z) are allowed.");
            }
        }

        schema.valid = schema.errors.length <= 0;
    }

    async function validateZipcode(schema: FormField | FieldSchema, value: any) {
        schema.touched = true;
        // Reset the error messages
        schema.errors = [];
        // Handles 12345, 12345-6789
        const formatCheck1 = /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
        // Handles 123456789
        const formatCheck2 = /^[0-9]{9}?$/.test(value);

        if (value === undefined) {
            schema.errors.push("Invalid zip code");
        } else if (value.length === 5 && formatCheck1) {
            schema.valid = true;
        } else if (value.length === 10 && formatCheck1) {
            schema.valid = true;
        } else if (value.length === 9 && formatCheck2) {
            schema.valid = true;
        } else {
            schema.errors.push("Invalid zip code");
        }

        // Passed all checks
        schema.valid = schema.errors.length <= 0;
    }

    async function validateUsername(
        schema: FormField | FieldSchema,
        value: any,
        compare: any = undefined
    ) {
        schema.touched = true;
        // Reset the error messages
        schema.errors = [];

        if (schema.subtype === "username") {
            // Test each rule individually for better readability
            // At least 8 characters long and no more than 255 characters
            if (value === undefined || value.length < 3 || value.length > 255) {
                schema.errors.push("Must be between 3 and 255 characters.");
            }

            // Only allow standard A-Z a-z 0-9 values @.
            const specialCharacterCheck = /[^A-Za-z0-9@.]/.test(value);
            if (specialCharacterCheck === true) {
                schema.errors.push("Must not contain special characters.");
            }

            // Passed the username rules
            schema.valid = schema.errors.length <= 0;
        }
    }

    function validateNationalIdNumber(schema: FormField | FieldSchema, value: any, compare?: any)
    {

        // Reset the error messages
        schema.errors = [];

        if (schema.type === "nationalIdNumber" && schema.label === "Social Security Number") {
                   
            const cleanedSsn = `${value}`.split("-").join("");
            const validDigits = /^\d{9}$/.test(cleanedSsn);
            schema.valid = validDigits && cleanedSsn.length === 9;
        }else
        {
            //Regex checks for the following: begins with a “9” and the 4th and 5th digits range from 50 to 65, 70 to 88, 90 to 92, and 94 to 99.
            const isValidITINDigits = /^(9\d{2})([ -]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2]|9[4-9])([ -]?)(\d{4})$/.test(value);

            schema.valid = isValidITINDigits;
        }

        if (schema.valid === false) {
            schema.errors.push(getErrorMessage(schema))
        }
    }

    async function validate(
        schema: FormField | FieldSchema,
        value: any,
        compare: any = undefined
    ) {
        schema.touched = true;
        // Reset the error messages
        schema.errors = [];

        // If schema has custom validation, let them, handle it
        if (schema.validate) {
            schema.validate(schema, value, compare);
        } else {
            if (schema.required) {
                const trimmedValue = typeof value === "string" ? value.replace(/\s/g, "") : value;
                // Check if the field is required first
                schema.valid =
                    trimmedValue !== undefined &&
                    trimmedValue !== "" &&
                    trimmedValue !== null &&
                    trimmedValue !== 0;
            }

            if (
                value === undefined ||
                value === "" ||
                value === null ||
                value === 0 ||
                (schema.type === "number" && isNaN(value)) ||
                !schema.required
            ) {
                // If empty we tell it to return the opposite of what is allowed
                schema.valid = !schema.required;
            } else {
                if (schema.type === "email") {
                    // Check if the field needs email validation
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    schema.valid = re.test(value);
                } else if (schema.type === "phone") {
                    // Check if the field needs phone number validation
                    const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                    schema.valid = re.test(value);
                } else if (schema.type === "zipcode") {
                    // Handles 12345, 12345-6789
                    const formatCheck1 = /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
                    // Handles 123456789
                    const formatCheck2 = /^[0-9]{9}?$/.test(value);

                    if (value === undefined) {
                        schema.errors.push("Invalid zip code");
                    } else if (value.length === 5 && formatCheck1) {
                        schema.valid = true;
                    } else if (value.length === 10 && formatCheck1) {
                        schema.valid = true;
                    } else if (value.length === 9 && formatCheck2) {
                        schema.valid = true;
                    } else {
                        schema.errors.push("Invalid zip code");
                    }

                    // Passed all checks
                    schema.valid = schema.errors.length <= 0;
                } else if (schema.type === "text") {
                    // Does not allow for urls into any input field
                    const urlCheck = /^https?:\/\/.*$/.test(value);
                    if (urlCheck === true) {
                        schema.errors.push("Must not contain url.");
                    }

                    if (
                        schema.name === "firstName" ||
                        schema.name === "middleName" ||
                        schema.name === "lastName"
                    ) {
                        // Only allow standard A-Z a-z values, single spaces and - '
                        const specialCharacterCheck = /[^A-Za-z-' ]/.test(value);
                        if (specialCharacterCheck === true) {
                            schema.errors.push("Must not contain special characters.");
                        }
                        const numberCheck = /\d/.test(value);
                        if (numberCheck === true) {
                            schema.errors.push("Must not contain numeric values.");
                        }

                        // Passed the validation rules
                        schema.valid = schema.errors.length <= 0;
                    }

                    if (schema.subtype === "username") {
                        // Test each rule individually for better readability
                        // At least 8 characters long and no more than 255 characters
                        if (value === undefined || value.length < 3 || value.length > 255) {
                            schema.errors.push("Must be between 3 and 255 characters.");
                        }

                        // Only allow standard A-Z a-z 0-9 values @.
                        const specialCharacterCheck = /[^A-Za-z0-9@.]/.test(value);
                        if (specialCharacterCheck === true) {
                            schema.errors.push("Sorry, only letters (a-z) and numbers (0-9) are allowed");
                        }

                        // Passed the username rules
                        schema.valid = schema.errors.length <= 0;
                    }

                    if (schema.subtype === "address") {
                        await validateAddressFields(schema, value);
                    }
          					
										const cleanedInputValue = value?.trim();

                    // Passed the username rules
                    schema.valid = cleanedInputValue?.length >= 1 && schema.errors.length <= 0;
                }
                else if (schema.type === "number") {
                    if (schema.name === "pageFrom" || schema.name === "pageTo") {
                        if (compare !== undefined) {
                            if (compare?.pageCount && value > compare?.pageCount) {
                                schema.errors.push(`Value must be between 1 and ${compare?.pageCount}`)
                            }
                        }
                    }
                    // Passed the username rules
                    schema.valid = schema.errors.length <= 0;
                }

                else if (schema.type === "textarea") {
                    const cleanedText = value.trim();
                    if (schema.subtype === 'stipulationText') {
                  
                        if (cleanedText === undefined || cleanedText.length > 1024) {
                            schema.errors.push("Input cannot be greater than 1024 characters");
                        }
                        else {
                            schema.errors = [];
                        }
                    }
                    if (schema.subtype !== 'stipulationText') {
                        if (cleanedText === undefined || cleanedText.length < 5 || cleanedText.length > 50) {
                            schema.errors.push("Must be between 5 and 50 characters.");
                        } else {
                            schema.errors = [];
                        }
                    }
                    if(schema.subtype === 'stipulationText' && 
                       !validateStipulationOverrideText(cleanedText)){
                        schema.valid = false;
                    }else{
                        schema.valid = schema.errors.length <= 0;
                    }
                    
                }
                else if (schema.type === "password") {
                    // Test each rule individually for better readability

                    // Adding the undefined checks because for some reason not all
                    // rules would get hit if value was undefined...

                    // Does not allow for urls into any input field
                    const urlCheck = /^https?:\/\/.*$/.test(value);
                    if (urlCheck === true) {
                        schema.errors.push("Sorry, URLs are not allowed.");
                    }
                    // At least 8 characters long and no more than 32 characters
                    if (value === undefined || value.length < 8 || value.length > 32) {
                        schema.errors.push("Must be between 8 and 32 characters.");
                    }

                    // Uppercase Alphabet.
                    if (value === undefined || /[A-Z]/.test(value) === false) {
                        schema.errors.push("Must contain an uppercase letter.");
                    }

                    // Lowercase Alphabet.
                    if (value === undefined || /[a-z]/.test(value) === false) {
                        schema.errors.push("Must contain a lowercase letter.");
                    }

                    // At least one number.
                    if (value === undefined || /[0-9]/.test(value) === false) {
                        schema.errors.push("Must contain a number.");
                    }

                    //Special Character.
                    if (value === undefined || !new RegExp("[$@$!%*#?&-]").test(value)) {
                        schema.errors.push("Must contain a special character.");
                    }

                    // Passed all 5 password rules
                    schema.valid = schema.errors.length <= 0;
                } else if (schema.type === "compare") {
                    schema.valid =
                        compare !== undefined &&
                        compare !== "" &&
                        compare !== null &&
                        value === compare;
                } else if (schema.type === "checkbox") {
                    schema.valid = value === true;
                } else if (schema.type === "nationalIdNumber") {
                   
                    const cleanedSsn = `${value}`.split("-").join("");
                    const validDigits = /^\d{9}$/.test(cleanedSsn);
                    schema.valid = validDigits && cleanedSsn.length === 9;
                }
            }

            if (schema.valid === false) {
                schema.errors.push(getErrorMessage(schema))
            }
        }
    }
    function validateAddressLine2Fields(
        unitType: FieldSchema,
        unitNumber: FieldSchema,
        unitTypeValue: any,
        unitNumberValue: any
    ): boolean {
        let valid = true;

        if (unitTypeValue && unitNumberValue) {
            unitType.required = true;
            unitNumber.required = true;
            valid = true;
        } else if (unitTypeValue && !unitNumberValue) {
            unitType.required = true;
            unitNumber.required = true;
            valid = false;
        } else if (!unitTypeValue && unitNumberValue) {
            unitType.required = true;
            unitNumber.required = true;
            valid = false;
        } else if (!unitTypeValue && !unitNumberValue) {
            unitType.required = false;
            unitNumber.required = false;
            unitType.valid = true;
            unitNumber.valid = true;
            unitType.errors = [];
            unitNumber.errors = [];
            valid = true;
        }

        return valid;
    }
    function validateIncome(schema: FormSchema, formValue: any, income: any) {
        const valid = true;
        // Required fields

        return valid;
    }

    function validateForm(schema: FormSchema, formValue: any): boolean {
        let valid = true;

        for (const [key, value] of Object.entries(schema)) {
            const keySchema = value as any;
            if (keySchema.type === "autocomplete") {
                const compareValue = formValue[key] || {};
                validate(
                    value as FormField,
                    compareValue[value.autocompleteProperty || ""] || ""
                );
            } else if (keySchema.validate) {
                keySchema.validate(
                    value as FormField,
                    formValue[key],
                    formValue[keySchema.compareField]
                );
            } else {
                validate(
                    value as FormField,
                    formValue[key],
                    formValue[keySchema.compareField]
                );
            }
            valid = valid && (value as any).valid;
        }

        return valid;
    }

    return {
        validate,
        validateForm,
        validateAddressFields,
        validateZipcode,
        validateUsername,
        validateAddressLine2Fields,
        validateIncome,
        validateNationalIdNumber,
        validateStipulationOverrideText
    };
}