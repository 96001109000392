<template>
  <searchpage pageName="Loans" :filters="filters"></searchpage>
</template>

<script setup>
  import searchpage from "@/components/layout/SearchPage.vue";

  var filters = [
    {
			description: 'Type',
			param: 'ProductTypeIds',
			type: 'radio',
			results: [
				{
                    id: 'skip', // wtf why
					name: 'All',
					checked: true
				},
				{
					id: 1,
					name: 'Line of Credit (LOC)',
					checked: false
				},
				{
					id: 2,
					name: 'Refinance',
					checked: false
				},
				{
					id: 3,
					name: 'In-School, Closed-end (PEL)',
					checked: false
				}
			]
		},
    {
      description: "Status",
      param: "StatusIds",
      results: [
        {
          name: "Active",
          id: 1,
          checked: false,
          param: "StatusIds",
        },
        {
          name: "Inactive",
          id: 2,
          checked: false,
          param: "StatusIds",
        },
      ],
    },
  ];
</script>
