import { defineStore } from "pinia";
import axios from "axios";
import { Permissions, Role, PermissionLevels } from "@/models/permissions";

interface State {
  currentUser: {
    roles: Role[] | null;
  };
  errorLogs: ErrorLogEntry[];
}

interface ErrorLogEntry {
  message: string;
  error: any;
  timestamp: Date;
}
interface UserInfo {
  roles: Role[] | null;
}

export const usePermissionsStore = defineStore({
  id: "user",
  state: (): State => ({
    currentUser: {
      roles: [],
    },
    errorLogs: [],
  }),

  getters: {hasRequiredPermission: (state) => (
        permissionIds: Permissions[],
        permissionLevel: PermissionLevels
      ): boolean => {
        const roles = Array.isArray(state.currentUser.roles)
          ? state.currentUser.roles
          : [];
        for (const role of roles) {
          for (const requiredPermissionId of permissionIds) {
            const rolePermission = role.rolePermissions?.find(
              (rp) => rp.permissionId === requiredPermissionId
            );
            if (rolePermission) {
              const combinedPermissionLevel =
                rolePermission.permissionLevel | permissionLevel;
              // check if the permission level is greater than or equal to the required permission level
              return (
                (rolePermission.permissionLevel & combinedPermissionLevel) !== 0
              );
            }
          }
        }
        return false;
      },

    // dont remove this, it may be useful in the near future
    //hasPermissionLevel: () => {
    //  return (permissionLevel: PermissionLevels, targetPermissionLevel: PermissionLevels): boolean => {
    //      return (permissionLevel & targetPermissionLevel) !== 0;
    //  };
    //},

    hasAccess: (state: State) => (
        permissionId: Permissions,
        permissionLevel: PermissionLevels
      ): boolean => {
        return (
          state.currentUser.roles?.some((role: any) =>
            role.rolePermissions?.some(
              (rp: any) =>
                rp.permissionId === permissionId &&
                rp.permissionLevel >= permissionLevel
            )
          ) || false
        );
      },
    currentUserRoleName: (state: any) => {
      const roles = state.currentUser.roles?.data ?? [];
      return roles.map((role: any) => role.name).filter(Boolean);
    },
  },

  actions: {
    // update the user role based on input
    setCurrentUser(userInfo: UserInfo) {
      this.currentUser.roles = userInfo.roles || [];
      console.log("Current User roles set", this.currentUser.roles);
      localStorage.setItem(
        "cache.UserRoles",
        JSON.stringify({ roles: this.currentUser.roles })
      );
    },

    // persist helper function to load user roles from cache
    async loadUserRolesFromStorage() {
      const cachedCurrentUser = localStorage.getItem("cache.UserRoles");
      if (cachedCurrentUser) {
        try {
          const { roles } = JSON.parse(cachedCurrentUser);
          if (Array.isArray(roles) && roles.length > 0) {
            this.setCurrentUser({ roles });
          } else {
            await this.getUserRoles();
          }
        } catch (error) {
          this.logError("Error parsing user roles from cache", error);
          this.clearCurrentUser();
        }
      } else {
        await this.getUserRoles();
      }
    },

    // retrieve the user roles from the server
    async getUserRoles() {
      try {
        const response = await axios.get("/api/users/roles");
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          this.setCurrentUser({ roles: response.data });
        } else {
          this.logError(
            "Received unexpected data format or empty user roles",
            response.data
          );
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          this.logError("Error loading user roles", error);
        } else {
          this.logError("Error loading user roles", error);
        }
        this.clearCurrentUser();
      }
    },

    clearCurrentUser() {
      this.currentUser = { roles: [] };
      localStorage.removeItem("cache.UserRoles");
    },
    logError(message: string, error: any) {
      const errorDetails: ErrorLogEntry = {
        message,
        error,
        timestamp: new Date(),
      };
      this.errorLogs.push(errorDetails);
      localStorage.setItem("errorLogs", JSON.stringify(this.errorLogs));
    }
  },
});
