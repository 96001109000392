import moment from "moment-timezone";
const localTimezone = moment.tz.guess();

export default function useFormatter(): any {
  function formatMoney(number: number, decimals = 2): string {
    const value = formatNumber(number, decimals);
    if (value.charAt(0) === "-") {
      return `-$${value.replace("-", "")}`;
    } else {
      return `$${value}`;
    }
  }

  function formatNumber(number: number, decimals = 2): string {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number);
  }

  function formatPhoneNumber(phoneStr: string): string {
    const cleanedPhone = phoneStr.replace(/[^0-9]/g, "");

    return cleanedPhone.length === 10
      ? `(${cleanedPhone.substring(0, 3)}) ${cleanedPhone.substring(
          3,
          6
        )}-${cleanedPhone.substring(6, 10)}`
      : phoneStr;
  }

  function formatZipCode(zipStr: string): string {
    if (zipStr) {
      const cleanedZip = zipStr?.includes("-")
        ? zipStr.split("-").join("")
        : `${zipStr}`;
      return cleanedZip.length === 9
        ? `${cleanedZip.substring(0, 5)}-${cleanedZip.substring(5, 9)}`
        : zipStr;
    } else return "";
  }

  function formatDecimalAsPercentage(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);
  }

  function formatDate(date: Date) {
    if (date) {
      return moment(date.toISOString(), moment.ISO_8601)
        .tz(localTimezone)
        .format("MMMM D, YYYY [at] h:mm A z");
    }
  }

  function formatDateTime(dateStr: string) {
    try {
      if (dateStr) {
        return moment(dateStr, moment.ISO_8601)
          .tz(moment.tz.guess())
          .format("MMMM D, YYYY [at] h:mm A z");
      }
    } catch (ex) {
      console.log("Could not format date time");
      return dateStr;
    }
  }

  return {
    formatDateTime,
    formatMoney,
    formatNumber,
    formatPhoneNumber,
    formatZipCode,
    formatDecimalAsPercentage,
    formatDate,
  };
}
