<template>
<div id="mainContent">
    <TabsGroup @open-tab="loadComponent" />
    <div class="xpage-content">
        <ComponentFactory  :identifier="tab" />    
    </div>    
</div>

</template>
<script setup>
import { ref, onMounted} from "vue"
import TabsGroup from "@/components/application/TabsGroup"
import ComponentFactory from "@/components/factory/ComponentFactory"
import { useApplicationStore } from '@/store/store/applicationVMStore'

const applicationStore = useApplicationStore();
const tab = ref('');

const loadComponent = (event)=> {
   tab.value = `${event}${applicationStore.pageIdentifier}`;
   localStorage.setItem(`${applicationStore.pageIdentifier}.currentTab`, event);
}

onMounted(() => {

    const defaultTab = 'ApplicationTab';

    const tabIdentifier = localStorage.getItem(`${applicationStore.pageIdentifier}.currentTab`);
    
    const tabExists = applicationStore.applicationTabs.some((tab) => tab.identifier == tabIdentifier);

    applicationStore.updateActiveTab((tabExists) ? tabIdentifier : defaultTab);

    tab.value = (tabExists) ? `${tabIdentifier}${applicationStore.pageIdentifier}` : `${defaultTab}${applicationStore.pageIdentifier}`;

});

</script>