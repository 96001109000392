import "./assets/css/responsive-tables.css"; // Responsive Tables CSS, may be redundant
// import "./assets/css/index.css"; // Tailwind CSS
import "./assets/css/css.css"; // Legacy CSS

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";
import { StipulationStrategy } from "@/types/stipulation";
import { LoanAccountStatementStipulationStrategy } from '@/strategies/LoanAccountStatementStipulationStrategy';
import { PayoffStatementStipulationStrategy } from '@/strategies/PayoffStatementStipulationStrategy';
import { RefiLoanAccountNumberStrategy } from '@/strategies/RefiLoanAccountNumberStrategy';
import { DefaultStipulationStrategy } from "@/strategies/DefaultStipulationStrategy";
import { reactive } from "vue";
import { plugin, defaultConfig } from "@formkit/vue";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { registerLicense } from "@syncfusion/ej2-base";
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
    FontAwesomeLayers,
    FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faFile as farFile,
    faFilePdf as farFilePdf,
    faSquare as farSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
    faBolt,
    faCoins,
    faFile,
    faFileArrowUp,
    faEye,
    faFloppyDisk,
    faGears,
    faCircleInfo,
    faFileContract,
    faFilePdf,
    faScaleBalanced,
    faCircleExclamation,
    faChevronCircleDown,
    faChevronCircleUp,
    faTrash,
    faPenToSquare,
    faChevronUp,
    faChevronDown,
    faFlag,
    faChevronLeft,
    faChevronRight,
    faCloudDownload,
    faPlus,
    faCircleChevronRight,
    faCircleXmark,
    faSquareCheck,
    faCirclePlus,
    faCircleMinus,
    faCaretDown,
    faCaretUp,
    faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

library.add(
    faBolt,
    faCoins,
    faFile,
    faFileArrowUp,
    faEye,
    faFloppyDisk,
    faGears,
    farFile,
    faCircleInfo,
    faFileContract,
    faFilePdf,
    faScaleBalanced,
    farFilePdf,
    faCircleExclamation,
    faChevronCircleUp,
    faChevronCircleDown,
    faTrash,
    faPenToSquare,
    faChevronUp,
    faChevronDown,
    faFlag,
    faChevronLeft,
    faChevronRight,
    faCloudDownload,
    faPlus,
    faCircleChevronRight,
    faCircleXmark,
    faSquareCheck,
    farSquare,
    faCirclePlus,
    faCircleMinus,
    faCaretDown,
    faCaretUp,
    faMagnifyingGlass
);

moment.updateLocale("en", { invalidDate: "" });

// add a request interceptor to all axios requests
axios.interceptors.request.use(
    (config: any) => {
        // add the authorization to the request headers
        config.headers[
            "Authorization"
        ] = `Bearer ${store.getters["oidcStore/oidcAccessToken"]}`;

        // check if the request requires json
        if (config.require === "json") {
            // set the responseType to 'json' and set the 'content-type' and 'accept' headers
            config.responseType = "json";
            config.headers["Content-Type"] = "application/json";
            config.headers["Accept"] = "application/json";

            // add a transformResponse function to the config
            config.transformResponse = function (data: any) {
                // try to parse the response data as json
                try {
                    return JSON.parse(data);
                } catch (error) {
                    // if the response data is not valid json, throw an error
                    throw new Error(`The response from the server is not valid JSON.`);
                }
            };
        }

        return config;
    },
    (error: any) => Promise.reject(error)
);

const app = createApp(App);
const pinia = createPinia();

const $store = reactive({
    mobileMenu: false,
});

app.config.globalProperties.$moment = moment;
app.config.globalProperties.localTimezone = moment.tz.guess();
app.config.globalProperties.$animate = { disrespectUserMotionPreference: true };

const strategies: StipulationStrategy[] = [
    new LoanAccountStatementStipulationStrategy(),
    new PayoffStatementStipulationStrategy(),
    new RefiLoanAccountNumberStrategy(),
    new DefaultStipulationStrategy()
];

app.provide('strategies', strategies);

app.provide("vueStore", $store);
app.provide("gtag", app.config.globalProperties.$gtag);
app.use(pinia);
app.use(router);
app.use(store);
app.use(plugin, defaultConfig);
app.use(autoAnimatePlugin);
app.use(ContextMenuPlugin);
//Syncfusion license key
// version 23???
// registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bd0diWnpac3NVTmJd;Mgo+DSMBMAY9C3t2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bd0diWnpac3NVT2hd');
// version 25
// registerLicense(
//   "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0ViXX9XcHBRRmNe;Mgo+DSMBMAY9C3t2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0ViXX9XcHBRRmNe"
// );
// version 24.2.3
registerLicense(
    "ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdE1iXnxac3JQQ2Bc;Mgo+DSMBMAY9C3t2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdE1iXnxac3JQQGVa"
);

app
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("font-awesome-layers", FontAwesomeLayers)
    .component("font-awesome-layer-text", FontAwesomeLayersText);

app.mount("#app");
