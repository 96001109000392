import { StipulationType } from "@/types/stipulation";
import { PayoffLoans } from '@/types/stipulation';
import { StipulationStrategy } from "@/types/stipulation";
import { StipulationTypes } from "@/types/types";

export class LoanAccountStatementStipulationStrategy implements StipulationStrategy {

    defaultText = "";

    setDefaultText(defaultText: string) {
        if(defaultText !== undefined && defaultText){
            this.defaultText = defaultText;
        }
    }
  


   mergeFields(refiLoan?: PayoffLoans, additionalParams?: Record<string, any>): string {
        if(refiLoan !== undefined){
            return this.defaultText
                .replace('{{ServicerName}}', refiLoan.servicerName)
                .replace('{{AccountNumber}}', refiLoan.accountNumberLastDigits);
        }

        return "";
   }

    isApplicable(stipulationType: StipulationTypes): boolean {
        return stipulationType == StipulationTypes.LoanAccountStatement;
    }
  }
  