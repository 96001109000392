<template>
    <div>
		<div v-if="route.params.referenceId" class="breadcrumb">
			<div class="xpage-breadcrumbs">
				<router-link to="/" class="icon-home"></router-link>
				<span>></span>
				<router-link to="/applications">Applications</router-link>
				<span>></span>
				<router-link :to="`/applications/${route.params.referenceId}`">{{
					route.params.referenceId
				}}</router-link>
			</div>
			<router-link :to="`/loans/${route.params.referenceId}`" v-if="viewModel?.loanId" class="loanservice-banner">
				This application has been completed and moved to Loan Service. View Loan
				>
			</router-link>
		</div>
	</div>
</template>
<script setup>
import { useApplicationStore } from "@/store/store/applicationVMStore";
import {storeToRefs} from 'pinia'
import { useRoute } from "vue-router";

const store = useApplicationStore();
const {viewModel} = storeToRefs(store)
const route = useRoute();

</script>
<style scoped>
.breadcrumb {
	font-family: "Montserrat", arial, sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.loanservice-banner {
	display: block;
	background: #0ecb8e;
	color: white;
	line-height: 44px;
	font-weight: 600;
	padding: 0px 35px;
	font-size: 14px;
	font-family: "Montserrat", arial, sans-serif;
}
</style>