import { defineStore } from "pinia";
import { ApplicantEvaluations } from "@/types/evaluation";
import axios from "axios";

export const useEvaluationsTabStore = defineStore({
  id: "evaluationsTab",
  state: () => ({
    applicantEvaluations: [] as ApplicantEvaluations[],
    selectedEvaluation: null,
    loading: false,
    nois: ["ADDMM", "SECFREEZE", "SSN12", "SSNMM"],
    aggregateEdDebtByApplicant: {} as Record<
      number,
      { item1: number; item2: number }
    >,
    programDebtByApplicant: {} as Record<
      number,
      { item1: number; item2: number }
    >,
  }),

  getters: {
    evaluations(state) {
      return state.applicantEvaluations;
    },
    isDataLoaded(state) {
      return state.loading;
    },
    hasNOI(state) {
      const creditAlerts = state.applicantEvaluations.flatMap(
        (item) =>
          item.creditEvaluation?.creditAlerts?.map((alert) =>
            alert.description.trim()
          ) || []
      );
      if (creditAlerts === undefined || creditAlerts.length == 0) return false;
      return creditAlerts.some((alert) => state.nois.includes(alert || ""));
    },
  },

  actions: {
    async getEvaluationDetails(referenceId: string) {
      try {
        const response = await axios.get(
          `/api/applications/${referenceId}/EvaluationDetails`
        );
        if (Object.keys(response.data).includes("applicantEvaluations")) {
          this.applicantEvaluations = response.data.applicantEvaluations;
          this.loading = true;
        }
      } catch (error) {
        console.error("Error fetching evaluation details:", error);
        this.loading = true;
      }
    },

    async getAggregateEdDebt(applicantId: number) {
      if (!applicantId) {
        console.error("Applicant ID is not provided.");
        return;
      }

      try {
        const response = await axios.get(
          `/api/applicants/${applicantId}/AggregateEdDebt`
        );
        this.aggregateEdDebtByApplicant = {
          ...this.aggregateEdDebtByApplicant,
          [applicantId]: response.data,
        }
      } catch (error) {
        console.error("Error getting aggregate education debt:", error);
      }
    },

    async getProgramDebt(applicantId: number) {
      if (!applicantId) {
        console.error("Applicant ID is not provided.");
        return;
      }

      try {
        const response = await axios.get(
          `/api/applicants/${applicantId}/ProgramDebt`
        );
        this.programDebtByApplicant = {
          ...this.programDebtByApplicant,
          [applicantId]: response.data,
        }
      } catch (error) {
        console.error("Error getting program debt:", error);
      }
    },
  },
});
