import axios from "axios";
import store from "../store";
import useFunctions from "./functions1";
import moment from "moment";
import { $api } from "@/services/api1";

const { decodeHTMLEncodedStr } = useFunctions();

function isValidSSN(ssn) {
  // remove all hyphens and spaces from the ssn
  ssn = ssn?.replace(/[- ]/g, "");

  // a valid 9 digit ssn is [3 digits not including 000, 666, 900-999][2 digits 01-99][4 digits 0001-9999]
  var regex = new RegExp(/^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/);

  // check if the ssn is valid using the regular expression, return true or false
  return regex.test(ssn);
}

function phoneFormat(phone) {
  // if the phone number length is 10 digits, then add dashes to it, otherwise return the phone unchanged
  return phone.length === 10
    ? `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`
    : phone;
}

function ssnFormat(ssn) {
  // if the ssn length is 9 digits and has five stars, change the ssn format to XXX-XX-0000
  return ssn?.length === 9 && ssn?.includes("*****")
    ? ssn.replace("*****", "XXX-XX-")
    : ssn;
}

function capitalize(input) {
  // this capitalizes each word of a string
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
}

function formatFromUtc(timestamp, format = "MMMM D, YYYY") {
  var notAvailable = "No Date Available";

  if (typeof timestamp !== "string" || !moment(timestamp).isValid()) {
    return notAvailable;
  }

  return moment.utc(timestamp).format(format) || notAvailable;
}

function compareObjects(obj1, obj2, path = []) {
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // description: takes an input of two objects and returns an array of all the differences between the two //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  let mismatchedKeys = [];
  if (typeof obj1 !== typeof obj2) {
    if (obj1 !== obj2) {
      mismatchedKeys.push(path);
    }
    return mismatchedKeys;
  }

  if (typeof obj1 !== "object" || obj1 === null) {
    if (obj1 !== obj2) {
      mismatchedKeys.push(path);
    }
    return mismatchedKeys;
  }

  for (const key of Object.keys(obj1)) {
    let newPath = [...path, key];
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      mismatchedKeys.push(newPath);
    } else {
      mismatchedKeys = [
        ...mismatchedKeys,
        ...compareObjects(obj1[key], obj2[key], newPath),
      ];
    }
  }
  return mismatchedKeys;
}

function compareIncomeObjects(obj1, obj2, path = []) {
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // description: takes an input of two objects and returns an array of all the differences between the two //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  let mismatchedKeys = [];

  if (typeof obj1 !== typeof obj2) {
    return mismatchedKeys;
  }

  if (typeof obj1 !== "object" || obj1 === null) {
    if (obj1 !== obj2) {
      mismatchedKeys.push(path);
    }
    return mismatchedKeys;
  }

  for (const key of Object.keys(obj1)) {
    let newPath = [...path, key];
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      mismatchedKeys.push(newPath);
    }
    // if
    else if (
      (obj1[key] === null) & (obj2[key] !== null) ||
      (obj1[key] !== null) & (obj2[key] === null)
    ) {
      mismatchedKeys.push(newPath);
    } else {
      mismatchedKeys = [
        ...mismatchedKeys,
        ...compareObjects(obj1[key], obj2[key], newPath),
      ];
    }
  }
  return mismatchedKeys;
}

function getLenderName(lenderId) {
  if (store.state.types["Lenders"]) {
    return (
      store.state.types["Lenders"].filter(
        (lender) => lender.id === Number(lenderId)
      )?.[0]?.name || "No Lender Provided"
    );
  } else {
    return "No Lender Provided";
  }
}

function displayProductType(typeId) {
  if (store.state.types["Products"]) {
    return (
      store.state.types["Products"].results.filter(
        (product) => product.id == typeId
      )?.[0]?.name || ""
    );
  } else {
    return "";
  }
}
function convertTypeIdToTypeName(type, typeId, property) {
  let types = store.state.types[type].results;
  const id = typeof typeId === "string" ? parseInt(typeId) : typeId;

  if (type === "Schools" || type === "GraduateDegreePrograms") {
    types = store.state.types[type];
  }
  const name = types.find((t) => t.id === id)?.name;
  return name;
}

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function checkIncomeFormChanges(object1, object2) {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // description: returns an html string of changes in a form by comparing the before object with the after object //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  var html = "";

  compareIncomeObjects(object1, object2).forEach((reference) => {
    var before = reference.reduce((o, key) => o[key], object2);
    var after = reference.reduce((o, key) => o[key], object1);
    if (before === after) {
      // do not add to table if the values are the same
      return;
    }
    // ignore the calculated incomeTotal, isEditing
    else if (reference[0] === "incomeTotal" || reference[0] === "isEditing") {
      return;
    } else {
      let lostype = "";
      if (reference[0] === "incomePeriodTypeId") {
        lostype = "IncomePeriods";

        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }
      // Handle income year
      if (reference[0] === "incomeYearTypeId") {
        lostype = "IncomeYears";
        reference[0] = "incomeYear";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }
      // Handle income type
      if (reference[0] === "incomeTypeId") {
        lostype = "Income";
        reference[0] = "incomeType";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }

      // get the last variable from the reference array, and convert the camelCase to add spaces
      var name = reference.pop().replace(/([a-z])([A-Z])/g, `$1 $2`);

      // Handle amount
      html += `<tr><td class="xbefore">${name}</td><td class="xafter">${before}</td><td class="xafter">${after}</td></tr>`;
    }
  });

  return html === "" ? false : `<table class='xmodal-changes'>${html}</table>`;
}
function checkFormChanges(object1, object2, options) {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // description: returns an html string of changes in a form by comparing the before object with the after object //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  var html = "";

  compareObjects(object1, object2).forEach((reference) => {
    var before = reference.reduce((o, key) => o[key], object2);
    var after = reference.reduce((o, key) => o[key], object1);
    if (before === after) {
      // do not add to table if the values are the same
      return;
    }
    // ignore the computed fields
    else if (reference[0] === "fullName" || reference[0] === "initials") {
      return;
    } else {
      let lostype = "";
      if (reference[0] === "citizenshipStatusId") {
        lostype = "Citizenship";
        reference[0] = "citizenshipStatus";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }

      if (
        (reference[0] === "contactInfo" &&
          reference[1] === "phoneNumberTypeId") ||
        reference[0] === "phoneNumberTypeId"
      ) {
        lostype = "PhoneNumbers";
        reference[0] = "phoneType";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }

      if (reference[0] === "addresses" && reference[2] === "stateId") {
        lostype = "States";
        reference[2] = "state";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }

      if (reference[0] === "schoolLevelTypeId") {
        lostype = schoolLevelLosTypeName(
          options?.productTypeId,
          options?.programTypeId
        );
        reference[2] = "yearInSchool";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }

      if (reference[0] === "degreeProgram") {
        lostype = "GraduateDegreePrograms";
        reference[0] = "program";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }
      // get the last variable from the reference array, and convert the camelCase to add spaces
      var name = reference.pop().replace(/([a-z])([A-Z])/g, `$1 $2`);

      html += `<tr><td class="xbefore">${name}</td><td class="xafter">${before}</td><td class="xafter">${after}</td></tr>`;
    }
  });

  return `<table class='xmodal-changes'>${html}</table>`;
}

function schoolLevelLosTypeName(productTypeId, programTypeId) {
  const ugloc = productTypeId === 1 && programTypeId === 1;
  const dgloc = productTypeId === 1 && programTypeId === 2;

  return dgloc ? "SchoolLevels2" : "SchoolLevels1";
}

function checkApplicationTabFormChanges(object1, object2) {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // description: returns an html string of changes in a form by comparing the before object with the after object //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  var html = "";

  compareObjects(object1, object2).forEach((reference) => {
    var before = reference.reduce((o, key) => o[key], object2);
    var after = reference.reduce((o, key) => o[key], object1);
    if (before === after) {
      // do not add to table if the values are the same
      return;
    }
    // ignore the computed fields
    else if (reference[0] === "fullName" || reference[0] === "initials") {
      return;
    } else {
      let lostype = "";
      if (reference[0] === "citizenshipStatusId") {
        lostype = "Citizenship";
        reference[0] = "citizenshipStatus";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }

      if (
        (reference[0] === "contactInfo" &&
          reference[1] === "phoneNumberTypeId") ||
        reference[0] === "phoneNumberTypeId"
      ) {
        lostype = "PhoneNumbers";
        reference[0] = "phoneType";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }

      if (reference[0] === "addresses" && reference[2] === "stateId") {
        lostype = "States";
        reference[2] = "state";
        before = convertTypeIdToTypeName(lostype, before, name);
        after = convertTypeIdToTypeName(lostype, after, name);
      }
      // get the last variable from the reference array, and convert the camelCase to add spaces
      var name = reference.pop().replace(/([a-z])([A-Z])/g, `$1 $2`);

      html += `<tr><td class="xbefore">${name}</td><td class="xafter">${before}</td><td class="xafter">${after}</td></tr>`;
    }
  });

  return `<table class='xmodal-changes'>${html}</table>`;
}

function checkLocalStorage(key, expirationTime = 86400000) {
  ///////////////////////////////////////////////////////////////////////////////////////////
  // description: checks the localStorage to determine if an item exits and is not expired //
  ///////////////////////////////////////////////////////////////////////////////////////////

  // if the localstore has the type
  if (localStorage.getItem(key)) {
    var localcache = JSON.parse(localStorage.getItem(key));

    // if the data has been cached for less than expirationTime (default 1 day (86400000 ms))
    if (Date.now() - localcache.timestamp < expirationTime) {
      
      return localcache;
    }
    
    //if not clear it
    localStorage.removeItem(key);
  }

  // return as false if localstorage check fails
  return false;
}

async function getLenders() {
  var localCache = checkLocalStorage(`cache.Lenders`);

  // if lender data is stored in localstorage
  if (localCache) {
    store.state.types["Lenders"] = localCache.data;
    return localCache.data;
  } else {
    await axios(`/api/lenders`, { require: "json" })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          // add the data to localstorage
          localStorage.setItem(
            `cache.Lenders`,
            JSON.stringify({ timestamp: Date.now(), data: response.data })
          );

          store.state.types["Lenders"] = response.data;
          return response.data;
        }
      })
      .catch((error) => {
        console.log(`API Error: /api/lenders`, error);
        return false;
      });
  }
}

async function getType(type, programTypeId = null) {
  var localData = checkLocalStorage(`cache.${type}`);

  const query = programTypeId !== null ? { programTypeId } : {};

  // if the type is stored in local storage
  if (localData) {
    store.state.types[type] = localData.data;
  } else {
    try {
      const response = await axios.get(`/api/types/${type}`, {
        params: query,
        responseType: "json",
      });

      // add the data to local storage
      localStorage.setItem(
        `cache.${type}`,
        JSON.stringify({ timestamp: Date.now(), data: response.data })
      );

      store.state.types[type] = response.data;
    } catch (error) {
      console.log(`API Error: /api/types/${type}`, error);
      return false;
    }
  }
}

async function getSchoolsByProgram(programTypeId, productTypeId, lenderId) {
  const key = "Schools";

  try {
    const response = await $api.schools.getSchoolsByProgram(
      programTypeId,
      productTypeId,
      lenderId
    );

    const schools = response.data.map((school) => {
      return {
        id: school.id,
        name: school.schoolName,
        code: school.schoolDOEId,
        branch: school.schoolBranchDOEId,
      };
    });

    localStorage.setItem(
      `cache.${key}`,
      JSON.stringify({ timestamp: Date.now(), data: schools })
    );

    store.state.types[key] = schools;
  } catch (error) {
    console.log(`API Error: /api/schools`, error);
    return false;
  }
}

async function getSchools() {
  const key = "Schools";

  try {
    const localData = checkLocalStorage(`cache.${key}`);

    if (localData) {
      store.state.types[key] = localData.data;
    } else {
      const response = await axios.get("/api/schools");

      const schools = response.data.map((school) => {
        return {
          id: school.id,
          name: school.schoolName,
          code: school.schoolDOEId,
          branch: school.schoolBranchDOEId,
        };
      });

      localStorage.setItem(
        `cache.${key}`,
        JSON.stringify({ timestamp: Date.now(), data: schools })
      );

      store.state.types[key] = schools;
    }
  } catch (error) {
    console.log(`API Error: /api/schools`, error);
    return false;
  }
}

async function getSchoolMajors() {
  const key = "Majors";

  try {
    const localData = checkLocalStorage(`cache.${key}`);

    if (localData) {
      store.state.types[key] = localData.data;
    } else {
      const response = await axios.get(`/api/schools/${key}`);

      localStorage.setItem(
        `cache.${key}`,
        JSON.stringify({ timestamp: Date.now(), data: response.data })
      );

      store.state.types[key] = response.data;
    }
  } catch (error) {
    console.log(`API Error: /api/schools/${key}`, error);
    return false;
  }
}

function cleanSearchText(text) {
  // remove all characters which are not a letter, number, space, period, comma, apostrophe, hyphen (then remove double spaces)
  return text
    ? text.replace(/[^a-zA-Z0-9 .,'-]/g, "").replace(/\s+/g, " ")
    : "";
}

function getSearchParameters(searchText, searchFilters, pageNumber) {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // description: this takes the search text and all active filters and outputs parameters for the url and post body //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  var parameters = {};
  var searchType = null;

  // if the user is searching
  if (searchText) {
    // make sure the user has typed at least 3 alphanumeric characters, and less than 200
    if (
      searchText?.replace(/[^0-9a-z]/gi, "").length >= 3 &&
      searchText?.length <= 200
    ) {
      // if the user is searching by name [the search text will contain a letter as the first character]
      if (searchText.charAt(0).match(/[a-z]/i)) {
        // set the search type to name
        searchType = "Name";
        parameters["SearchType"] = 1;
        parameters["SearchTerms"] = searchText.split(" ");
        parameters["names"] = searchText.split(" ");

        // if the user is searching by ssn [the search text will validate with the isValidSSN function]
      } else if (isValidSSN(searchText)) {
        // set the search type to ssn
        searchType = "SSN";
        parameters["SearchType"] = 3;
        parameters["SearchTerm"] = searchText;
        parameters["ssn"] = searchText;

        // if the user is searching by application id [the search text will contain an amount of numbers not equal to 9]
      } else {
        // set the search type to application id
        searchType = "Application ID";
        parameters["SearchType"] = 2;
        parameters["SearchTerm"] = searchText;
        parameters["referenceId"] = searchText;
      }
    } else {
      return { parameters: {}, searchType: "Unknown" };
    }
  }

  // if search filters are set
  if (searchFilters) {
    // loop through each of the filters
    searchFilters.forEach((section) => {
      // for each of the fields inside that filter
      section.results.forEach((field) => {
        // if the field has children
        if (field.children) {
          // for each of the children which are checked
          field.children
            .filter((child) => child.checked)
            .forEach((child) => {
              // check if the parameter exists, otherwise create it as a blank array
              parameters[section.param] = parameters[section.param] || [];

              // append the value to the parameter
              parameters[section.param].push(child.id);
            });

          // else if the field is checked
        } else if (field.checked && field.id != "skip") {
          // check if the parameter exists, otherwise create it as a blank array
          parameters[section.param] = parameters[section.param] || [];

          // append the value to the parameter
          parameters[section.param].push(field.id);
        }
      });
    });
  }

  // if there is a pagenumber
  if (pageNumber > 1) {
    parameters["PageNumber"] = pageNumber;
  }

  // return the search parameters
  return { parameters: parameters || {}, searchType: searchType || "Unknown" };
}

async function getApplications(searchText, searchFilters, pageNumber) {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // description: fetches applications by name, ssn, or application id and formats the response //
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const searchParameters = getSearchParameters(
    searchText,
    searchFilters,
    pageNumber
  );
  // check to make sure we are not trying to destructure undefined
  if (!searchParameters) {
    console.log("Search parameters are undefined");
    return;
  }

  const { parameters, searchType } = searchParameters;

  return await axios(`/api/applications/dashboard/search`, {
    method: "POST",
    require: "json",
    data: parameters,
    searchType: searchType,
    postTime: Date.now(),
  })
    .then((response) => {
      return formatApplicationResponse(response, searchText, searchType);
    })
    .catch((error) => {
      console.log(`API Error: /api/applications/dashboard/search/`, error);
      return false;
    });
}

function formatApplicationResponse(response, searchText, searchType) {
  // make sure the applications data is not empty
  if (response.data.data.length > 0 && Array.isArray(response.data.data)) {
    // for each of the applications
    response.data.data.forEach((application) => {
      application.applicants.forEach((applicant) => {
        // if this is the primary applicant
        if (applicant.applicantTypeId === 1) {
          // set the full name of the applicant (put the two names in an array, filter out the null names, and join with a space)
          application.name =
            [
              decodeHTMLEncodedStr(applicant.firstName),
              decodeHTMLEncodedStr(applicant.lastName),
            ]
              .filter((n) => n)
              .join(" ") || "No Name";

          // set the initials of the applicant
          application.initials = `${applicant.firstName?.charAt(0) || ""}${
            applicant.lastName?.charAt(0) || ""
          }`.trim();

          // if this is the secondary applicant
        } else if (applicant.applicantTypeId === 2) {
          application.nameCoApplicant = applicant.firstName
            ? `${decodeHTMLEncodedStr(
                applicant.firstName
              )} ${decodeHTMLEncodedStr(applicant.lastName)}`
            : "No Name";
        }
      });

      // if the lenders api has already loaded
      if (store.state.types["Lenders"]) {
        // for each of the lenders that matches the current application's lenderId
        store.state.types["Lenders"]
          .filter((lender) => lender.id == application.lenderId)
          .forEach((lender) => {
            // set the application lenderName
            application.defaultLenderName = lender.name;
          });
      }

      if (searchType) {
        // set the type of search
        application.searchCategory = "Application";

        // if the user is searching by name
        if (response.config.searchType == "Name") {
          // create an array of names by splitting on the space
          var searchNames = searchText.split(" ");

          ["name", "nameCoApplicant"].forEach((name) => {
            // if the name or namecoapplicant exists
            if (application[name]) {
              // loop through each of the search names, sorted by longest search name
              searchNames
                .sort((a, b) => b.length - a.length)
                .forEach((searchName) => {
                  // create a regular expression to match the search text ('ig' means [case insensitve] [match all])
                  var match = new RegExp(`(${searchName})`, "ig");

                  // replace the matched text with empty html tags <></> surrounding it
                  application[name] = application[name].replace(
                    match,
                    `<>$1</>`
                  );
                });

              // replace all empty tags <></> with <span class='xhighlight'></span> to highlight the search terms in html
              application[name] = application[name]
                .replace(/<>/g, "<span class='xhighlight'>")
                .replace(/<\/>/g, "</span>");
            }
          });

          // if the user is searching by application id
        } else if (response.config.searchType == "Application ID") {
          // create a regular expression to match the search text ('ig' means [case insensitve] [match all])
          var match = new RegExp(`(${searchText})`, "ig");

          // replace the matched text with a span to highlight it
          application.searchNotification =
            "ID: " +
            application.referenceId.replace(
              match,
              `<span class="xhighlight">$1</span>`
            );

          // if the user is searching by ssn
        } else if (response.config.searchType == "SSN") {
          // highlight the ssn search text
          application.searchNotification = `SSN: <span class="xhighlight">${searchText}</span>`;
        }
      }
    });
  }

  return response;
}

async function getLoans(searchText, searchFilters, pageNumber) {
  //////////////////////////////////////////////////////////////////////////////////
  // description: fetches loans by name, ssn, or loan id and formats the response //
  //////////////////////////////////////////////////////////////////////////////////

  var { parameters, searchType } = getSearchParameters(
    searchText,
    searchFilters,
    pageNumber
  );

  if (pageNumber > 1) {
    parameters["PageNumber"] = pageNumber;
    parameters["pageSize"] = 30;
    parameters["page"] = {
      index: pageNumber,
      size: 30,
    };
  } else {
    parameters["pageSize"] = 30;
    parameters["page"] = {
      index: 1,
      size: 30,
    };
  }

  return await axios(`/api/loanservice/loans/filter`, {
    method: "POST",
    require: "json",
    data: parameters,
    searchType: searchType,
    postTime: Date.now(),
  })
    .then((response) => {
      // make sure the loan data is not empty
      if (
        response.data?.items?.length > 0 &&
        Array.isArray(response.data?.items)
      ) {
        // for each of the loans
        response.data.items.forEach((application) => {
          application.borrowers.forEach((borrower) => {
            // if this is the primary borrower
            if (borrower.typeId === 1) {
              // set the full name on the borrower
              application.name = borrower.firstName
                ? `${decodeHTMLEncodedStr(
                    borrower.firstName
                  )} ${decodeHTMLEncodedStr(borrower.lastName)}`
                : "No Name";

              // set the initials of the borrower
              application.initials = `${borrower.firstName?.charAt(0) || ""}${
                borrower.lastName?.charAt(0) || ""
              }`.trim();
            }
          });

          // if the lenders api has already loaded
          if (store.state.types["Lenders"]) {
            // for each of the lenders that matches the current application's lenderId
            store.state.types["Lenders"]
              .filter((lender) => lender.id == application.lenderId)
              .forEach((lender) => {
                // set the application lenderName
                application.lenderName = lender.name;
              });
          }

          if (searchType) {
            // set the type of search
            application.searchCategory = "Loan";

            // if the user is searching by name
            if (response.config.searchType == "Name") {
              // create an array of names by splitting on the space
              var searchNames = searchText.split(" ");

              ["name", "nameCoApplicant"].forEach((name) => {
                // if the name or namecoapplicant exists
                if (application[name]) {
                  // loop through each of the search names, sorted by longest search name
                  searchNames
                    .sort((a, b) => b.length - a.length)
                    .forEach((searchName) => {
                      // create a regular expression to match the search text ('ig' means [case insensitve] [match all])
                      var match = new RegExp(`(${searchName})`, "ig");

                      // replace the matched text with empty html tags <></> surrounding it
                      application[name] = application[name].replace(
                        match,
                        `<>$1</>`
                      );
                    });

                  // replace all empty tags <></> with <span class='xhighlight'></span> to highlight the search terms in html
                  application[name] = application[name]
                    .replace(/<>/g, "<span class='xhighlight'>")
                    .replace(/<\/>/g, "</span>");
                }
              });

              // if the user is searching by application id
            } else if (response.config.searchType == "Application ID") {
              // create a regular expression to match the search text ('ig' means [case insensitve] [match all])
              var match = new RegExp(`(${searchText})`, "ig");

              // replace the matched text with a span to highlight it
              application.searchNotification =
                "ID: " +
                application.referenceId.replace(
                  match,
                  `<span class="xhighlight">$1</span>`
                );

              // if the user is searching by ssn
            } else if (response.config.searchType == "SSN") {
              // highlight the ssn search text
              application.searchNotification = `SSN: <span class="xhighlight">${searchText}</span>`;
            }
          }
        });
      }

      return response;
    })
    .catch((error) => {
      console.log(`API Error: /api/loanservice/loans/filter/`, error);
      return false;
    });
}

function objectToUrlString(parameters) {
  var finalUrl = "";

  Object.entries(parameters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // add to the final url as a string split by commas
      finalUrl += `${key}=[${value.map((value) => `"${value}"`).join(",")}]&`;
    } else {
      finalUrl += `${key}=${value}&`;
    }
  });

  return finalUrl.slice(0, -1);
}

async function logPageView(page) {
  ///////////////////////////////////////////////////
  // description: logs a page view to localStorage //
  ///////////////////////////////////////////////////

  var recentlyViewed = [];

  // if the localstore has any recently viewed pages
  if (localStorage.getItem("cache.RecentlyViewedPages")) {
    // retreive the data
    recentlyViewed = JSON.parse(
      localStorage.getItem("cache.RecentlyViewedPages")
    );
  }

  // remove all entries which match the current id/page
  recentlyViewed = recentlyViewed.filter(
    (entry) => entry.id !== page.id || entry.page !== page.page
  );

  // add the current page to the beginning of the array
  recentlyViewed.unshift(page);

  // add the data to localstorage
  localStorage.setItem(
    "cache.RecentlyViewedPages",
    JSON.stringify(recentlyViewed)
  );
}

function objectToHtmlTable(obj) {
  var html = "<table>";
  for (var key in obj) {
    var item = obj[key];
    var value =
      typeof item === "object"
        ? objectToHtmlTable(item)
        : item?.toString() || "";
    html += "<tr><td>" + key + "</td><td>" + value + "</tr>";
  }
  html += "</table>";
  return html;
}

function openModal(options) {
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // description: opens a modal (the component is '@/components/Modal.vue', included in @/components/layout/Page.vue) //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // close open modals
  store.state.modal.active = false;

  // open this modal
  store.state.modal.loading = false;
  store.state.modal.active = true;
  store.state.modal.title = options.title || options;
  store.state.modal.description = options.description || "";
  store.state.modal.html = options.html || "";
  store.state.modal.buttons = options.buttons || "";
  store.state.modal.fullscreen = options.fullscreen || false;
}

function setModalLoading() {
  store.state.modal.loading = true;
}
function closeModal() {
  /////////////////////////////////
  // description: closes a modal //
  /////////////////////////////////

  store.state.modal.active = false;
}

function openPdfModal(options) {
  store.state.pdfModal.active = false;

  store.state.pdfModal.active = true;
  store.state.pdfModal.title = options.title || options;
  store.state.pdfModal.description = options.description || "";
  store.state.pdfModal.docId = options.docId.toString() || "";
  store.state.pdfModal.buttons = options.buttons || "";
  store.state.pdfModal.fullscreen = options.fullscreen || false;
  store.state.pdfModal.documents = options.documents || undefined;
  store.state.pdfModal.application = options.application || undefined;
  store.state.pdfModal.splitting = options.splitting || false;
}

function closePdfModal() {
  store.state.pdfModal.active = false;
}

// export the following functions
export default Object.assign(
  {},
  {
    capitalize,
    checkIncomeFormChanges,
    checkFormChanges,
    checkLocalStorage,
    cleanSearchText,
    closeModal,
    closePdfModal,
    compareObjects,
    displayProductType,
    getApplications,
    formatApplicationResponse,
    getLenderName,
    getLenders,
    getLoans,
    getSearchParameters,
    getType,
    isValidSSN,
    logPageView,
    objectToHtmlTable,
    objectToUrlString,
    openModal,
    openPdfModal,
    setModalLoading,
    phoneFormat,
    ssnFormat,
    formatFromUtc,
    getSchools,
		getSchoolsByProgram,
    getSchoolMajors,
  }
);
