import { defineStore } from "pinia";
import axios from "axios";
import {
  ApplicationSummary,
  ApplicationComment,
  ApplicationDetailVM,
  ApplicationStatuses,
  TabVM,
  TabApplicationVM,
  ApplicantFormVM,
  AuthorizedThirdPartyVM,
} from "@/types/application";
import { $api } from "@/services/api1";
import { BaseOption, LOSType, StipContextOption } from "@/types/types";
import functions from "@/use/functions";
import {
  AddressDictionary,
  ApplicantAddress,
  ApplicantVM,
} from "@/types/applicant";
import useFunctions from "@/use/functions1";
import { Tag } from "@/types/tag";

const { decodeHTMLEncodedStr } = useFunctions();
interface Application {
  detailViewModel: ApplicationDetailVM;
  tabApplicationViewModel: TabApplicationVM;
}

export const useApplicationStore = defineStore("applicationVM", {
  state: () => {
    return {
      applicationSummary: null as ApplicationSummary | null,
      application: null as Application | null,
      applicationComment: [] as ApplicationComment[],
      viewDisabled: false,
      applicantPreviousAddresses: {} as AddressDictionary,
      applicants: [] as ApplicantVM[],
      applicantsTab: [] as ApplicantFormVM[],
      applicationMetadata: {
        referenceId: null as string | null,
        applicationId: null as number | null,
        lenderId: null as number | null,
        productTypeId: null as number | null,
        programTypeId: null as number | null,
      },
    };
  },

  getters: {
    viewModel(state): ApplicationDetailVM {
      return state.application?.detailViewModel || ({} as ApplicationDetailVM);
    },
    applicationTab(state): TabApplicationVM {
      return (
        state.application?.tabApplicationViewModel || ({} as TabApplicationVM)
      );
    },
    summary(state): ApplicationSummary {
      return state.applicationSummary || ({} as ApplicationSummary);
    },
    applicationStatuses(state): LOSType[] {
      return (
        state.applicationSummary?.applicationStatuses.filter(
          (a) => a.visible
        ) || []
      );
    },
    pageIdentifier(state): string {
      return state.application?.detailViewModel?.pageIdentifier || "";
    },
    applicationTabs(state): TabVM[] {
      return state.application?.detailViewModel?.tabs || [];
    },
    applicationTags(state): Tag[] {
      return state.application?.detailViewModel.tags || [];
    },
    applicationStatus(state): string {
      return state.application?.detailViewModel?.statusName || "";
    },
    applicationStatusId(state): number {
      return state.application?.detailViewModel?.statusId || 0;
    },
    applicationId(state): number {
      return state.application?.detailViewModel?.id || 0;
    },
    referenceId(state): string {
      return state.application?.detailViewModel.referenceId || "";
    },
    Applicants(state): ApplicantVM[] {
      return state.applicants;
    },
    ApplicantSelected(state): ApplicantFormVM {
      return (
        state.applicantsTab.find((a) => a.selected) || ({} as ApplicantFormVM)
      );
    },
    AuthorizedSelected(state): AuthorizedThirdPartyVM {
      return (
        state.application?.tabApplicationViewModel.authorizedThirdParty.find(
          (a) => a.selected
        ) || ({} as AuthorizedThirdPartyVM)
      );
    },
    AuthorizeParties(state): AuthorizedThirdPartyVM[] {
      return (
        state.application?.tabApplicationViewModel.authorizedThirdParty || []
      );
    },
    applicantIds(state): number[] {
      const response = Array<number>();
      if (state.application?.detailViewModel.hasPrimaryApplicant)
        response.push(state.application?.detailViewModel.primaryApplicant.id);
      if (state.application?.detailViewModel.hasCoApplicant)
        response.push(state.application?.detailViewModel.coApplicant.id);
      return response;
    },
    comments(state): any {
      return state.applicationComment ?? [];
    },
    applicantOptions(state): BaseOption[] {
      const applicantOption: BaseOption[] = [];
      if (state.application?.detailViewModel.hasPrimaryApplicant) {
        applicantOption.push({
          value: state.application?.detailViewModel.primaryApplicant.id,
          label: `${state.application?.detailViewModel.primaryApplicant.fullName} - ${state.application?.detailViewModel.primaryApplicant.applicantTypeName}`,
        });
      }

      if (state.application?.detailViewModel.hasCoApplicant) {
        applicantOption.push({
          value: state.application?.detailViewModel.coApplicant.id,
          label: `${state.application?.detailViewModel.coApplicant.fullName} - ${state.application?.detailViewModel.coApplicant.applicantTypeName}`,
        });
      }
      return applicantOption;
    },

    applicantStipContextOptions(state): StipContextOption[] {
      const applicantOption: StipContextOption[] = [];
      if (state.application?.detailViewModel.hasPrimaryApplicant) {
        applicantOption.push({
          text: `${state.application?.detailViewModel.primaryApplicant.fullName} - ${state.application?.detailViewModel.primaryApplicant.applicantTypeName}`,
          id: `ApplicantIdSelected_${state.application?.detailViewModel.primaryApplicant.id}`,
          showItemOnClick: true,
        });
      }

      if (state.application?.detailViewModel.hasCoApplicant) {
        applicantOption.push({
          id: `ApplicantIdSelected_${state.application?.detailViewModel.coApplicant.id}`,
          text: `${state.application?.detailViewModel.coApplicant.fullName} - ${state.application?.detailViewModel.coApplicant.applicantTypeName}`,
          showItemOnClick: true,
        });
      }
      return applicantOption;
    },
    applicantUserId: (state) => {
      return (applicantId: number) => {
        return state.applicants.find((a) => a.id == applicantId)?.userId;
      };
    },
    applicantFullName: (state) => {
      return (applicantId: number) => {
        if (
          state.application?.detailViewModel.primaryApplicant.id == applicantId
        ) {
          return state.application?.detailViewModel.primaryApplicant.fullName;
        }

        if (state.application?.detailViewModel.coApplicant.id == applicantId) {
          return state.application?.detailViewModel.coApplicant.fullName;
        }

        return "";
      };
    },
    applicantAddresses: (state) => {
      return (applicantId: number) => {
        return state.applicantPreviousAddresses[applicantId] || [];
      };
    },
    canGenerateLegalPacket: (state): boolean => {
      return (
        state.application?.detailViewModel?.statusId ===
        ApplicationStatuses.SubmittedProcessing
      );
    },
    canAccessByStatuses: (state) => {
      return (statusIds: number[]) => {
        return statusIds.some(
          (id) => id == state.application?.detailViewModel.statusId
        );
      };
    },
  },

  actions: {
    setApplicationMetadata(metadata: {
      referenceId: string;
      applicationId: number;
      lenderId: number;
      productTypeId: number;
      programTypeId: number;
    }) {
      this.applicationMetadata = metadata;
    },
    updateActiveApplicant(applicantId: number) {
      this.applicants.forEach((applicant) => {
        applicant.selected = false;
      });

      this.applicantsTab.forEach((applicant) => {
        applicant.selected = false;
      });

      const applicant = this.applicants.find((a) => a.id == applicantId);

      const applicantTab = this.applicantsTab.find((a) => a.id == applicantId);

      if (applicant) {
        applicant.selected = true;
      }

      if (applicantTab) {
        applicantTab.selected = true;
      }
    },

    updateAuthorizedParties(personId: number) {
      this.application?.tabApplicationViewModel.authorizedThirdParty.forEach(
        (person) => {
          person.selected = false;
        }
      );

      const authorizedPerson =
        this.application?.tabApplicationViewModel.authorizedThirdParty.find(
          (p) => p.id == personId
        );
      if (authorizedPerson) {
        authorizedPerson.selected = true;
      }
    },
    updateActiveTab(identifier: string) {
      this.application?.detailViewModel.tabs.forEach((element) => {
        element.isTabActive = false;
      });

      const tab = this.application?.detailViewModel.tabs.find(
        (t) => t.identifier == identifier
      );
      if (tab) {
        tab.isTabActive = true;
      }
    },
    async getApplicationComment() {
      try {
        const response = await $api.applications.getApplicationComments(
          this.applicationId
        );
        if (response) {
          this.applicationComment = response;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getApplicationSummary(referenceId: string) {
      try {
        var response = await axios.get(
          `/api/applications/${referenceId}/summary`
        );

        if (
          response.data !== undefined &&
          response.data.results !== undefined
        ) {
          this.applicationSummary = response.data.results;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getApplicationRoute(referenceId: string) {
      if (this.applicationMetadata?.referenceId === referenceId) {
        return this.applicationMetadata.productTypeId;
      }

      try {
        const response = await axios.get(
          `/api/applications/${referenceId}/slim`
        );

        if (response !== undefined && response.data !== undefined) {
          this.applicationMetadata.productTypeId = response.data.productTypeId;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getApplication(referenceId: string) {
      try {
        const response = await axios.get(
          `/api/applications/${referenceId}/detail`
        );

        if (
          response.data !== undefined &&
          response.data.results !== undefined
        ) {
          this.application = response.data.results;
          if (this.application?.detailViewModel) {
            this.applicationMetadata.productTypeId =
              this.application.detailViewModel.productTypeId;
            this.setupApplicants();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    setupApplicants() {
      this.applicants = [];
      this.applicantsTab = [];

      if (this.application?.detailViewModel.hasPrimaryApplicant) {
        this.application.detailViewModel.primaryApplicant.selected = true;
        this.application.tabApplicationViewModel.primaryApplicant.selected =
          true;
        this.applicants.push(this.application.detailViewModel.primaryApplicant);
        this.applicantsTab.push(
          this.application.tabApplicationViewModel.primaryApplicant
        );
      }
      if (this.application?.detailViewModel.hasCoApplicant) {
        this.application.detailViewModel.coApplicant.selected =
          !this.application?.detailViewModel.hasPrimaryApplicant;
        this.application.tabApplicationViewModel.coApplicant.selected =
          !this.application?.detailViewModel.hasPrimaryApplicant;

        this.applicants.push(this.application?.detailViewModel.coApplicant);
        this.applicantsTab.push(
          this.application.tabApplicationViewModel.coApplicant
        );
      }
    },

    async getPreviousAddresses(applicantId: number) {
      try {
        const fiveMinutes = 300000;
        const cacheApplicantAddress = functions.checkLocalStorage(
          `cache.${applicantId}Address`,
          fiveMinutes
        );
        if (cacheApplicantAddress) {
          this.applicantPreviousAddresses[applicantId] =
            cacheApplicantAddress.data;
        } else {
          const response = await axios.get(
            `/api/applicants/${applicantId}/previousAddresses`
          );
          if (response) {
            response.data.forEach((a: ApplicantAddress) => {
              a.address1 = decodeHTMLEncodedStr(a.address1);
              a.address2 = decodeHTMLEncodedStr(a.address2);
              a.city = decodeHTMLEncodedStr(a.city);
            });

            localStorage.setItem(
              `cache.${applicantId}Address`,
              JSON.stringify({ timestamp: Date.now(), data: response.data })
            );

            this.applicantPreviousAddresses[applicantId] = response.data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
