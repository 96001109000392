import { defineStore } from 'pinia'

import { $api } from "@/services/api1";
import {  StipulationVM, StipulationType, StipulationTabVM, SectionVM } from '@/types/stipulation';
import { BaseOption, LOSType } from '@/types/types';





export const useStipulationStore = defineStore('stipulationStore', {
    state: () => { 
        return {
            stipulationVM: {} as StipulationTabVM,
            showAddStipsButton: false
        }
    },

    getters: {
        Stips(state): StipulationVM[]  
        {
            if(state.stipulationVM.stipulations?.length > 0)
                return state.stipulationVM.stipulations;
            return [];
        },
        Sections(state): SectionVM[]{
            return state.stipulationVM.sections || [];
        },
        StipulationTypes(state): StipulationType[]{
            return state.stipulationVM.stipulationModal.stipulationTypes || [];
        },
        GoodThruDate(state): string{
            return state.stipulationVM.goodThruDate;
        },
        RefiLoan(state) {
            return (refiLoanId: number) => {
                    return state.stipulationVM.stipulationModal.payOffLoans.find(pl => pl.refiLoanId == refiLoanId);
            };
            
        },
        LoansOptions(state): BaseOption[] {
            return state.stipulationVM.stipulationModal.payOffLoans?.length > 0
                ? state.stipulationVM.stipulationModal.payOffLoans.map(payoffLoan => ({
                    label: payoffLoan.accountNumber,
                    value: payoffLoan.refiLoanId
                }))
            : [];
          },
    },

    actions: {
        setAddStipButton(applicationStatusId: number){
            this.showAddStipsButton = (applicationStatusId == 2 || applicationStatusId == 3);
        },
        updateStipulation(newObj: StipulationVM) {
            const objWithIdIndex = this.stipulationVM.stipulations.findIndex((obj) => obj.id === newObj.id);
            if (objWithIdIndex > -1) {
                this.stipulationVM.stipulations[objWithIdIndex] = Object.assign({} as StipulationVM, newObj);
            }
          
        },
        updateStipulations(stips: StipulationVM[]){
            this.stipulationVM.stipulations = stips;
        },
        async getStipulations(applicationId: number)
        {
           try {
             const response = await $api.applications.getApplicationStipulationList(applicationId);

             if(response?.results){
                this.stipulationVM = response.results;
             }

           } catch (error) {
              console.error(error);
           }
        }
    }
});