import { StipulationAdditionalParamaters, StipulationType } from "@/types/stipulation";
import { PayoffLoans } from '@/types/stipulation';
import { StipulationTypes } from "@/types/types";
import { StipulationStrategy } from "@/types/stipulation";

export class PayoffStatementStipulationStrategy implements StipulationStrategy {
    id = 0;
    defaultText = "";

  
    setDefaultText(defaultText: string) {
        if(defaultText !== undefined && defaultText){
            this.defaultText = defaultText;
        }

    }

    mergeFields(refiLoan?: PayoffLoans, additionalParams?: StipulationAdditionalParamaters): string {
        if(refiLoan !== undefined && additionalParams !== undefined){

            return this.defaultText
                .replace("{{CreditorName}}", refiLoan.creditorName)
                .replace("{{GoodThruDate}}", additionalParams.goodThruDate || "")
                .replace('{{AccountNumber}}', refiLoan.accountNumberLastDigits)
        }

        return "";
   }
  


    
    isApplicable(stipulationType: StipulationTypes): boolean {
        return stipulationType == StipulationTypes.PayoffStatement;
    }
  }