import { defineStore } from 'pinia'
import axios from "axios";
import {  Type, TypeDictionary } from '@/types/types';
import functions from "@/use/functions";
import useFunctions from "@/use/functions1";
import { $api } from "@/services/api1";

const { sortLosTypesByName } = useFunctions();

const cacheKey = "cache.v2";

export const useTypeStore = defineStore('types', {
    state: () => { 
        return {
            types: {} as TypeDictionary
        }
    },

    getters: {
      getActiveTypes: (state) => {
        return (type: Type) => (state.types[type]) ? state.types[type].filter((t) => t.visible) : []
      },

      getTypes: (state) => {
        return (type: Type) => (state.types[type]) ? state.types[type] : []
      },

      getApplicationStatusIdByName: (state) => {
        return (statusName: string) => (state.types[Type.ApplicationStatus]) ? state.types[Type.ApplicationStatus].find((t) => t.name == statusName)?.id : 0;
      },

      isTypeAvailable: (state) =>{
        return (type:Type) => state.types[type] !== undefined
      },

      getOptionsByType: (state) => {
        return (type: Type) => state.types[type].map((value: any) => {
          return {
            label: value.name,
            value: value.id,
          };
        });
      },
      getDocumentTypeOptions: (state) => {
        const documentTypes = sortLosTypesByName(
          state.types[Type.DocumentType]
        );
        
        return documentTypes?.map((d: any) => {
            return {
              label: d.name,
              value: d.id,
            };
          });
        
      },

      getCitizenShiptStatusOptions: (state) => {
        const sortedCitizenshipStatuses = sortLosTypesByName(
          state.types[Type.Citizenship]
        );

        return sortedCitizenshipStatuses?.map((c: any) => {
              return {
                label: c.name,
                value: c.id,
              };
        });
      },

      getStateOptions: (state) => {
        const sortedStates = sortLosTypesByName(
        	state.types[Type.States]
        );

        return sortedStates?.map((c: any) => {
        	return {
        		label: c.name,
        		value: c.id,
        	}
        });

      },

      getCountryOptions: (state) => {

          const sortedCountries = sortLosTypesByName(
              state.types[Type.Countries]
        );
        return sortedCountries?.map((c: any) => {
        	return {
        		label: c.name,
        		value: c.id,
        	};
        });
      },

      getPhoneOptions: (state) =>{
          const sortedPhoneTypes = sortLosTypesByName(
          	 state.types[Type.PhoneNumbers]
          );
          
          return sortedPhoneTypes?.map((c: any) => {
          	return {
          		label: c.name,
          		value: c.id,
          	};
          });
      },
       

      getRequirementStatusOptions: (state) =>{

        const sortedRequirementStatuses = sortLosTypesByName(
          state.types[Type.RequirementStatuses]
        );
        
         return sortedRequirementStatuses?.map(
          (o: any) => {
            return {
            text: o.name,
            id: `RequirementStatusIdSelected_${o.id}`,
            showItemOnClick: true,
            items: [{
              text: o.description,
              id: `RequirementStatusIdSelectedDescription_${o.id}`,
            }]
            } 
        });
      } 
    },

    actions: {

        async  getType(type: Type, programTypeId = null) {
            var localData = functions.checkLocalStorage(`${cacheKey}v2.${type}`);
          
            const query = programTypeId !== null ? { programTypeId } : {};
          
           
            if (localData) {
               this.types[type] = localData.data;
            } else {
              try {
                const response = await axios.get(`/api/types/${type}`, {
                  params: query,
                  responseType: "json",
                });

                //TODO: need to create an end-point that does this work. Suffice this today.
                if(type == Type.Membership)
                {
                  response.data.results = sortLosTypesByName(response.data.results);
                }
          
                
                localStorage.setItem(
                  `${cacheKey}v2.${type}`,
                  JSON.stringify({ timestamp: Date.now(), data: response.data.results })
                );
          
               this.types[type] = response.data.results;
              } catch (error) {
                console.log(`API Error: /api/types/${type}`, error);
              }
            }
          },

        async getDocServiceType()
        {
          var localData = functions.checkLocalStorage(`${cacheKey}.${Type.DocumentType}`);
            if (localData) {
              this.types[Type.DocumentType] = localData.data;
            } else {
                try {

                  const response = await axios.get(`/api/types/doc/${Type.DocumentType}`);
            
                  localStorage.setItem(
                    `${cacheKey}.${Type.DocumentType}`,
                    JSON.stringify({ timestamp: Date.now(), data: response.data.results })
                  );
            
                  this.types[Type.DocumentType] = response.data.results;

                } catch (error) {
                  console.log(`API Error: /api/types/doc/${Type.DocumentType}`, error);
                }
            }
        },

        async  getStipulationTypes(type: Type) {
          var localData = functions.checkLocalStorage(`cache.${type}`);

          // if the type is stored in localstorage
          if (localData) {
            this.types[type] = localData.data;
          } else {
            const response = await $api.types.getStipulationTypes();
            if (response && response.length > 0) {
              localStorage.setItem(`cache.${type}`, JSON.stringify({ timestamp: Date.now(), data: response }));
              this.types[type]  = response;
            }
          }
        },

        async getSchoolMajors() {
        
          try {
            const localData = functions.checkLocalStorage(`${cacheKey}.${Type.Majors}`);
        
            if (localData) {
                this.types[Type.Majors] = localData.data;
            } else {
                const response = await axios.get(`/api/schools/${Type.Majors}`);
          
                localStorage.setItem(
                  `${cacheKey}.${Type.Majors}`,
                  JSON.stringify({ timestamp: Date.now(), data: response.data.results })
                );
          
                this.types[Type.Majors] = response.data.results;
            }
          } catch (error) {
            console.log(`API Error: /api/schools/${Type.Majors}`, error);
            return false;
          }
        },

        async  getGraduateDegreePrograms() {
      
          try {
              const localData = functions.checkLocalStorage(`${cacheKey}.${Type.GraduateDegreePrograms}`);
      
              if (localData) {
                this.types[Type.GraduateDegreePrograms] = localData.data;
              } else {
                const graduatePrograms = await $api.schools.getGraduateDegreePrograms();
      
                  localStorage.setItem(`${cacheKey}.${Type.GraduateDegreePrograms}`, 
                    JSON.stringify({ timestamp: Date.now(), data: graduatePrograms.data.results }));
      
                  this.types[Type.GraduateDegreePrograms] = graduatePrograms.data.results;
              }
          } catch (error) {
              return false;
          }
      },
      async getApplicantType() {
        try {
          const response = await axios.get(`/api/applicant`);
          return response.data;
        } catch (error) {
          console.log(`API Error: /api/applicant`, error);
          return null;
        }
      }
    }
})