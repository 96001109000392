import { StipulationVM, StipulationAdditionalParamaters, StipulationType } from "@/types/stipulation";
import { PayoffLoans } from '@/types/stipulation';
import { StipulationTypes } from "@/types/types";
import {StipulationStrategy } from "@/types/stipulation";

export class DefaultStipulationStrategy implements StipulationStrategy {


    defaultText = "";

    setDefaultText(defaultText: string) {
        if(defaultText !== undefined && defaultText){
            this.defaultText = defaultText;
        }
    }
  

    mergeFields(refiLoan?: PayoffLoans, additionalParams?:  StipulationAdditionalParamaters): string {
       
        console.log(additionalParams);
        console.log(this.defaultText);
        if (additionalParams !== undefined && 
            additionalParams.lenderName !== undefined && 
            additionalParams.phoneNumber !== undefined) {
            return this.defaultText
                .replace("{{LenderName}}", additionalParams.lenderName)
                .replace("{{Lenders/TollFreeNumber}}", additionalParams.phoneNumber)
        }       

        return "";
   }

    isApplicable(stipulationType: StipulationTypes): boolean {
        const allowedStipulationTypes = [
            StipulationTypes.AdditionalIncome,
            StipulationTypes.ContactCBRCorrection,
            StipulationTypes.EstablishMembership,
            StipulationTypes.UnfreezeCredit,
            StipulationTypes.UnSuppressBureau
        ];

        return  allowedStipulationTypes.includes(stipulationType);
    }
}