<template>
  <div :key="renderKey">
    <loader v-if="application.loading" />
    <page v-else>
      <div v-if="application.data.loan">
        <div class="xpage-breadcrumbs">
          <router-link to="/dashboard" class="icon-home"></router-link>
          <span>></span>
          <router-link to="/loans">Loans</router-link>
          <span>></span>
          <router-link :to="`/loans/${route.params.referenceId}`">
            {{ borrowers["primary"].fullName }}
          </router-link>
        </div>
        <LoansSingleProfile
          :application="application"
          :borrowers="borrowers"
          :route="route"
          :store="store"
          :referenceId="route.params.referenceId"
          :localTimezone="localTimezone"
          :format-money="formatMoney"
          :toggleApplicationDetails="toggleApplicationDetails"
        />
        <LoansSingleDetails
          :application="application"
          :borrowers="borrowers"
          :route="route"
          :store="store"
          :referenceId="route.params.referenceId"
          :localTimezone="localTimezone"
        />
        <div
          class="xpage-content"
          v-auto-animate="{ ...$animate, duration: 100 }"
        >
          <div
            class="xpage-content-page"
            id="xapplication-tab"
            v-auto-animate="$animate"
          >
            <LoansSingleAccountDetails
              :loanDetails="application.data.loan"
              :formatMoney="formatMoney"
              :localTimezone="localTimezone"
              :application="application"
              :route="route"
              :borrowers="borrowers"
              :store="store"
              :referenceId="route.params.referenceId"
              :commentsId="application.commentIds"
              :commentData="application.commentData"
              :draw="selectedDraw"
            />
            <PermissionsWrapper
              :permissionIds="[Permissions.Draws]"
              :permissionLevel="PermissionLevels.Read"
            >
              <LoansSingleDraws
                :application="application"
                :selectDraw="selectDraw"
                :availableToDraw="availableToDrawData"
                :selectedDraw="selectedDraw?.value"
                :drawStatusTypes="drawStatusTypes"
                :enrollmentTerms="enrollmentTerms"
                @update:selectedDraw="selectedDraw.value = $event"
                :draws="application.data.loan.draws"
              />
            </PermissionsWrapper>
            <PermissionsWrapper
              :permissionIds="[Permissions.Disbursements]"
              :permissionLevel="PermissionLevels.Read"
            >
              <LoansSingleDisbursements
                :application="application"
                :draws="application.data.loan.draws"
                :draw="selectedDraw"
                :disbursements="selectedDraw?.disbursements || []"
                :disbursement="selectedDisbursement"
                :disbursementStatusTypes="disbursementStatusTypes"
                :drawDisbursementStatusReasonTypes="
                  drawDisbursementStatusReasonTypes
                "
                :selectDraw="selectDraw"
                :selectDisbursement="selectDisbursement"
                :selectedDraw="selectedDraw?.value"
                :selectedDisbursement="selectedDisbursement?.value"
                @updateSelectedDraw="updateSelectedDraw"
              />
            </PermissionsWrapper>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="xpage-breadcrumbs">
          <router-link to="/dashboard" class="icon-home"></router-link>
          <span>></span>
          <router-link to="/loans">Loans</router-link>
        </div>
        <div class="xwarningbox warning-box">
          The Loan ID {{ route.params.referenceId }} failed to load or does not
          exist
        </div>
      </div>
    </page>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, nextTick, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import page from "@/components/layout/Page.vue";
import loader from "@/components/Loader.vue";
import functions from "@/use/functions.js";
import useFunctions from "@/use/functions1";
import useFormatter from "@/use/formatter";
import { $api } from "@/services/api1";
import axios from "axios";
import LoansSingleProfile from "@/components/loans-single/LoansSingleProfile.vue";
import LoansSingleDetails from "@/components/loans-single/LoansSingleDetails.vue";
import LoansSingleAccountDetails from "@/components/loans-single/LoansSingleAccountDetails.vue";
import LoansSingleDraws from "@/components/loans-single/LoansSingleDraws.vue";
import LoansSingleDisbursements from "@/components/loans-single/LoansSingleDisbursements.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";

const { decodeHTMLEncodedStr } = useFunctions();
const { formatMoney } = useFormatter();

const renderKey = ref(0);

const route = useRoute();

const application = reactive({
  commentIds: [],
  commentData: [],
  currentTab: "",
  creditReport: false,
  data: false,
  documents: false,
  focusComments: false,
  formDisabled: true,
  formSaved: false,
  identitySelected: false,
  identityDocuments: false,
  loading: true,
  mobileHidden: true,
  newComment: "",
  ratePlanData: [{}],
  showComments: false,
  showDetails: false,
  showOwnerDropdown: false,
  types: {},
  tabs: {
    application: "incomplete",
    income: "incomplete",
    identity: "incomplete",
    esign: "incomplete",
    membership: "incomplete",
  },
});

const borrowers = reactive({
  primary: false,
  secondary: false,
  backup: {},
  selected: "primary",
});

const availableToDrawData = ref({
  availableToDrawAmount: null,
  drawsEnabled: false,
});

const selectedDraw = ref(null);
const selectedDisbursement = reactive({
  value: null,
});
const drawStatusTypes = ref([]);
const enrollmentTerms = ref([]);
const disbursementStatusTypes = ref([]);
const drawDisbursementStatusReasonTypes = ref([]);

const selectDisbursement = (disbursement) => {
  selectedDisbursement.value = disbursement;
};

const updateSelectedDraw = (newDisbursement) => {
  selectedDraw.value = newDisbursement;
};

const loanReferenceId = ref(route.params.referenceId);

let timeoutId = null;

function selectDraw(draw) {
  selectedDraw.value = draw;
  if (draw.disbursements && draw.disbursements.length > 0) {
    selectDisbursement(draw.disbursements[0]);
  } else {
    selectDisbursement(null);
  }
  availableToDraw();
}

// This should to be refactored into several smaller functions
async function getLoan() {
  const response = await $api.loans.getLoan(route.params.referenceId);
  if (response.data) {
    // set the application data equal to the response data for application
    application.data = response.data;

    // if the lenders api has loaded
    if (store.state.types["Lenders"]) {
      // set the lender name
      application.data.loan.lenderName =
        store.state.types["Lenders"].find(
          (lender) => lender.id == application.data.loan.lenderId
        )?.name || "";
    }

    // for each of the borrowers
    response.data.loan.borrowers.forEach((borrower) => {
      // if the borrower does not have the following data models, create them as blank objects/arrays so they can be used in vue v-model
      borrower.contactInfo = borrower.contactInfo || {};
      borrower.membership = borrower.membership || {};
      borrower.addresses = borrower.addresses || [{}];

      if (borrower.borrowerAddress) {
        const address = borrower.borrowerAddress;
        address.addressLineOne = decodeHTMLEncodedStr(address.addressLineOne);
        address.city = decodeHTMLEncodedStr(address.city);
        address.addressLineTwo = decodeHTMLEncodedStr(address.addressLineTwo);
      }

      borrower.firstName = decodeHTMLEncodedStr(borrower.firstName);
      borrower.middleName = decodeHTMLEncodedStr(borrower.middleName);
      borrower.lastName = decodeHTMLEncodedStr(borrower.lastName);

      // create a full name for the borrower, use "no name" if both first/last names are blank
      borrower.fullName =
        `${decodeHTMLEncodedStr(borrower.firstName) || ""} ${
          decodeHTMLEncodedStr(borrower.lastName) || ""
        }`.trim() || "No Name";

      // create initials for the borrower from the first character of each names
      borrower.initials = `${borrower.firstName?.charAt(0) || ""}${
        borrower.lastName?.charAt(0) || ""
      }`.trim();

      if (borrower.borrowerContact) {
        // create a phone number display for the borrower
        borrower.borrowerContact.phoneDisplay = functions.phoneFormat(
          borrower.borrowerContact?.phoneNumber || ""
        );
      }

      // if the borrowerTypeId is "1" then the borrower is "primary", otherwise the borrower is "secondary"
      var borrowerType = borrower.borrowerTypeId == 1 ? "primary" : "secondary";

      // set the borrower data based on the borrowerType
      borrowers[borrowerType] = borrower;
    });

    // determine the total draw amount
    application.data.loan.totalDrawAmount = 0;

    application.commentIds.push({ loan: application.data.loan.id });

    // for each of the draws
    response.data.loan.draws.forEach((draw, index) => {
      // the total draw amount does not include draws which are terminated (id 7) or school unable to certify (id 4)
      if (draw.drawStatusTypeId != 7 && draw.drawStatusTypeId != 4) {
        // add the draw amount to the total draw amount
        application.data.totalDrawAmount =
          application.data.totalDrawAmount + Number(draw.requestedAmount);
      }
      if (index === 0) {
        selectDraw(draw);
      }

      if (
        draw.disbursements &&
        draw.disbursements.length > 0 &&
        !selectedDisbursement.value
      ) {
        selectDisbursement(draw.disbursements[0]);
      }
      // if the user is a part of cusc
      if (store.state.oidcStore.user.los_access == "Yes") {
        application.commentIds.push({ draw: draw.id });
      }
    });

    const lastDraw =
      response.data.loan.draws?.[response.data.loan.draws?.length - 1];

    if (lastDraw?.schoolCode && lastDraw?.schoolBranch) {
      const response = await $api.schools.getSchool(
        lastDraw.schoolCode,
        lastDraw.schoolBranch
      );

      if (response.data) {
        application.schoolData = response.data;
      }
    }
  }
  availableToDraw();
}

async function availableToDraw() {
  if (!application || !application.data || !application.data.loan) {
    return;
  }

  // todo: remove/refactor when building new tabs
  if (application.data.loan.productType != 1) {
    return;
  }

  const loanId = application.data.loan.id;
  try {
    const response = await axios.get(
      `/api/loanservice/loans/${loanId}/availabletodrawamount`
    );

    let currentAmount = 0;
    if (selectedDraw.value) {
      currentAmount = parseFloat(selectedDraw.value.requestedAmount || 0);
    }

    availableToDrawData.value = {
      availableToDrawAmount:
        response.data.availableToDrawAmount + currentAmount,
      drawsEnabled: response.data.drawsEnabled,
    };
  } catch (error) {
    console.log("Error getting available to borrow", error);
  }
}

async function getDrawStatusTypes() {
  const url = "/api/LoanTypes/DrawStatusType";
  try {
    const response = await axios.get(url);
    drawStatusTypes.value = response.data.results;
  } catch (error) {
    console.log("Error getting draw types:", error);
  }
}

async function getLoanComments() {
  const url = `/api/comments/loan/${application.data.loan.id}`;
  try {
    const response = await axios.get(url);
    application.commentData = response.data;
  } catch (error) {
    console.log("Error getting loan comments:", error);
  }
}

async function getDisbursementStatusTypes() {
  const url = "/api/LoanTypes/DisbursementStatusType";
  try {
    const response = await axios.get(url);
    disbursementStatusTypes.value = response.data.results;
  } catch (error) {
    console.log("Error getting disbursement types:", error);
  }
}

async function getDrawDisbursementStatusReasonTypes() {
  const url = "/api/LoanTypes/DrawDisbursementStatusReasonType";
  try {
    const response = await axios.get(url);
    drawDisbursementStatusReasonTypes.value = response.data.results;
  } catch (error) {
    console.log("Error getting disbursement status reason types:", error);
  }
}

async function getEnrollmentTerms() {
  const url = `/api/loanservice/EnrollmentTerms`;
  try {
    const response = await axios.get(url);
    enrollmentTerms.value = response.data;
  } catch (error) {
    console.log("Error getting draw types:", error);
  }
}

function toggleApplicationDetails() {
  // toggle("application.showDetails");
  application.showDetails = !application.showDetails;

  if (localStorage.getItem("cache.applicationDetailsDisplay") == "false") {
    // set the data
    localStorage.setItem("cache.applicationDetailsDisplay", true);
  } else {
    // set the data
    localStorage.setItem("cache.applicationDetailsDisplay", false);
  }
}

onMounted(async () => {
  await functions.getLenders();
  await getLoan();
  await availableToDraw();
  await getDrawStatusTypes();
  await getEnrollmentTerms();
  await getDisbursementStatusTypes();
  await getDrawDisbursementStatusReasonTypes();
  await getLoanComments();

  await nextTick();
  timeoutId = setTimeout(() => {
    application.loading = false;
  }, 500);

  if (!application || !application.data || !application.data.loan) {
    console.log("application.data.loan is not defined");
    return;
  }

  functions.logPageView({
    id: loanReferenceId.value,
    page: "loans",
    category: "Loan",
    timestamp: Date.now(),
    firstName: borrowers["primary"].firstName,
    lastName: borrowers["primary"].lastName,
    name: borrowers["primary"].fullName,
    lenderId: application.data.loan.lenderId,
  });
});
</script>

<style scoped>
.readonly {
  pointer-events: none;
  opacity: 0.5;
}

.warning-box {
  margin: 30px;
}
</style>
