<template>
  <div class="xpage-content-page">
    <Loader v-if="loading" />
    <Page v-else>
      <div class="xpage-breadcrumbs">
        <router-link to="/" class="icon-home"></router-link>
        <span>></span>
        <router-link :to="`/${props.pageName.toLowerCase()}`">{{
          props.pageName
        }}</router-link>
      </div>
      <div class="xpage-inner">
        <!-- Layout Buttons -->
        <div class="xpage-settings">
          <button
            @click="applications.layout = 'boxes'"
            class="icon-th"
            :class="{ xselected: applications.layout == 'boxes' }"></button>
          <button
            @click="applications.layout = 'table'"
            class="icon-list"
            :class="{ xselected: applications.layout == 'table' }"></button>
        </div>
        <!-- Search Bar -->
        <h2>{{ props.pageName }}</h2>
        <div class="xpage-searchbar-wrapper">
          <div class="xpage-searchbar">
            <div class="xsearchbox">
              <div class="xsearchbox-field">
                <input
                  type="text"
                  v-model.trim="applications.search.typing"
                  @focus="applications.search.focus = true"
                  @blur="blurSearchbox"
                  @keyup="handleKeyup($event)"
                  @keyup.enter="
                    triggerSearch(applications.search.typing);
                    $event.target.blur();
                  "
                  :placeholder="`Search by Name, SSN, ${props.pageName.slice(
                    0,
                    -1
                  )} ID...`" />
                <i
                  class="icon-cancel"
                  v-if="applications.search.typing.length > 0"
                  @click="
                    clearSearchbox();
                    triggerSearch(null, 1);
                  "></i>
              </div>
              <div class="xsearchbox-submit">
                <button
                  @click="triggerSearch(applications.search.typing, 1)"
                  class="icon-search">
                  <span>Search</span>
                </button>
              </div>
            </div>
            <div
              v-if="applications.search.error" class="search-error">
              Search must contain at least 3 characters.
            </div>
            <div
              class="xpage-searchbar-dropdown"
              v-if="
                applications.search.typing.length >= 3 &&
                applications.search.focus
              ">
              <nav>
                <router-link
                  v-for="(application, index) in applications.dataDropdown"
                  :key="index"
                  :to="`/${props.pageName.toLowerCase()}/${
                    application.referenceId
                  }`">
                  <div class="xusericon" data-size="small">
                    <div class="xusericon-picture">
                      {{ application.initials || "" }}
                    </div>
                    <div class="xusericon-gender" data-gender=""></div>
                  </div>
                  <div class="xheader-search-dropdown-result">
                    <h4 v-html="application.name || 'No Name'"></h4>
                    <h5
                      v-if="application.searchNotification"
                      v-html="application.searchNotification"></h5>
                    <h5
                      :title="
                        application.lenderName || application.defaultLenderName
                      "
                      v-else
                      v-html="
                        application.lenderName || application.defaultLenderName
                      "></h5>
                  </div>
                </router-link>
              </nav>
            </div>
            <div
              class="xpage-searchbar-dropdown"
              v-if="
                applications.search.typing.length < 3 &&
                applications.search.focus
              ">
              <nav v-if="applications.recentlyViewed">
                <div
                  class="xpage-searchbar-dropdown-title"
                  v-if="
                    applications.recentlyViewed &&
                    applications.search.typing.length < 3
                  ">
                  Recently Viewed
                </div>
                <router-link
                  v-for="(
                    application, index
                  ) in applications.recentlyViewed.slice(0, 5)"
                  :key="index"
                  :to="`/${props.pageName.toLowerCase()}/${
                  props.pageName === 'loans'
                    ? application.loan.id
                  : application.id
                  }`">
                  <div class="xusericon" data-size="small">
                    <div class="xusericon-picture">
                      {{ application.firstName?.charAt(0)
                      }}{{ application.lastName?.charAt(0) }}
                    </div>
                    <!-- <div class="xusericon-gender" data-gender=""></div> -->
                  </div>
                  <div class="xheader-search-dropdown-result">
                    <h4 v-html="application.name || 'No Name'"></h4>
                    <h5
                      v-html="
                        functions.getLenderName(application.lenderId)
                      "></h5>
                  </div>
                </router-link>
                <div
                  class="xpage-searchbar-dropdown-showmore"
                  @click="headerSearch = ''">
                  Show More
                </div>
              </nav>
            </div>
          </div>
          <div class="mobilemenu">
            <button
              class="xsearchfilters icon-menu"
              @click="applications.filterDisplay = !applications.filterDisplay">
              Filters
            </button>
            <button
              @click="applications.layout = 'boxes'"
              class="icon-th button-style"
              :class="{ xselected: applications.layout == 'boxes' }"
              ></button>
            <button
              @click="applications.layout = 'table'"
              class="icon-list button-style"
              :class="{ xselected: applications.layout == 'table' }"
              ></button>
          </div>
          <div class="clear"></div>
        </div>
        <div class="xpage-search-wrapper">
          <SearchFilter
            :filters="applications.filters"
            :pageName="props.pageName"
            :triggerSearch="triggerSearch"
            :selectFilter="selectFilter"
            :clearSearchbox="clearSearchbox"
            :clearFilter="clearFilter"
            :clearAllFiltersAndSearch="clearAllFiltersAndSearch"
            :selectedFilters="selectedFilters"
            @selectedFilter="selectFilter()"
            :sectionResultsExceedMaxVisible="sectionResultsExceedMaxVisible"
            :filteredSectionResults="filteredSectionResults"
            :maxFilterResults="maxFilterResults"
            :applications="applications"
            :pageTotal="applications.query.pageTotal" />
          <div class="xpage-search-results">
            <div class="xpage-search-results-title">
              <div v-if="typeof applications.data === 'string'">
                {{ applications.data }}
              </div>
              <!-- Pagination -->
              <div v-else class="pagination-area-container">
                <div>
                  Found {{ applications.query.totalCount }} Result<b
                    v-if="applications.query.totalCount != 1"
                    >s</b
                  >
                  <span v-if="applications.query.totalCount > 30"
                    >Page {{ applications.query.pageNumber }} of
                    {{ Math.ceil(applications.query.totalCount / 30) }}</span
                  >
                </div>
                <div class="pagination-component">
                  <Pagination
                    v-if="applications.query.pageTotal > 1"
                    :applications="applications"
                    :triggerSearch="triggerSearch"
                    :pageName="applications.query.pageNumber"
                    :pageTotal="applications.query.pageTotal" />
                </div>
              </div>
            </div>
            <div class="xpage-search-results-buttons">
              <div
                class="xfilter-button"
                v-if="applications.search.active"
                @click="
                  clearSearchbox();
                  triggerSearch(null, 1);
                ">
                {{ "Search: " + applications.search.text }}
              </div>
              <div
                class="xfilter-button"
                v-for="(item, index) in selectedFilters()"
                @click="clearFilter(item)"
                :key="index">
                {{ item.name }}
              </div>
              <div
                class="xfilter-button"
                v-if="selectedFilters().length + applications.search.active > 1"
                @click="clearAllFiltersAndSearch()"
                data-type="clear">
                Clear All
              </div>
            </div>
            <div
              class="xpage-search-results-data"
              v-auto-animate="{ duration: 100, ...$animate }">
              <div
                :class="['x' + applications.layout]"
                v-if="
                  applications.data.length > 0 &&
                  typeof applications.data !== 'string'
                "
                v-auto-animate="{ duration: 100, ...$animate }">
                <router-link
                  class="xrow"
                  @click="setSelectedApplication(application)"
                  v-for="(application, index) in applications.data"
                  :key="index"
                  :to="`/${props.pageName.toLowerCase()}/${
                    application.referenceId
                  }`">
                  <div class="xcell xname">
                    <div class="xusericon" data-size="small">
                      <div class="xusericon-picture">
                        {{ decodeHTMLEncodedStr(application.initials) || "" }}
                      </div>
                      <!-- <div class="xusericon-gender" data-xgender=""></div> -->
                    </div>
                    <div class="xpage-openapps-box-name">
                      <h4
                        v-html="
                          decodeHTMLEncodedStr(application.name) || 'No Name'
                        "></h4>
                      <h5
                        :title="
                          application.lenderName ||
                          application.defaultLenderName
                        ">
                        {{
                          application.lenderName ||
                          application.defaultLenderName
                        }}
                      </h5>
                      <h6 v-html="application.searchNotification"></h6>
                    </div>
                  </div>
                  <!-- Legacy Code; usefulness undetermined -->
                  <!-- <div class="xcell xstatuses" style="display: none">
                    <div class="xicon-on-tile xtooltip">
                      <span class="xtooltiptext">Income</span>
                      <div class="xicon-status icon-ok-circle"></div>
                      <i class="icon-money"></i>
                    </div>
                    <div class="xicon-on-tile xtooltip">
                      <span class="xtooltiptext">Identity</span>
                      <div class="xicon-status icon-ok-circle"></div>
                      <i class="icon-adult"></i>
                    </div>
                    <div class="xicon-on-tile xtooltip">
                      <span class="xtooltiptext">Membership</span>
                      <div class="xicon-status icon-ok-circle"></div>
                      <i class="icon-bank"></i>
                    </div>
                    <div class="xicon-on-tile xtooltip">
                      <span class="xtooltiptext">E-Sign</span>
                      <div class="xicon-status icon-ok-circle"></div>
                      <i class="icon-pencil-alt"></i>
                    </div>
                    <div class="xicon-on-tile xtooltip">
                      <span class="xtooltiptext">Credit</span>
                      <div class="xicon-status icon-ok-circle"></div>
                      <i class="icon-credit-card"></i>
                    </div>
                    <div class="xicon-on-tile xtooltip">
                      <span class="xtooltiptext">E-Sign</span>
                      <div class="xicon-status icon-ok-circle"></div>
                      <i class="icon-pencil-alt"></i>
                    </div>
                    <div class="xicon-on-tile xtooltip">
                      <span class="xtooltiptext">Credit</span>
                      <div class="xicon-status icon-ok-circle"></div>
                      <i class="icon-credit-card"></i>
                    </div>
                  </div> -->
                  <div class="xcell xsearchtext">
                    <h6 v-html="application.nameCoApplicant"></h6>
                  </div>
                  <div class="xcell xprogress">
                    <div class="xpage-openapps-box-bar">
                      <div
                        class="xpage-openapps-box-bar-inner width-100"
                        ></div>
                    </div>
                    <h4>Progress</h4>
                  </div>
                  <div class="xcell xdate">
                    <h3>
                      {{
                        application.createdAtUtc
                          ? $moment(`${application.createdAtUtc}Z`)
                              .tz(localTimezone)
                              .format("MMM D, YYYY")
                          : "No Date"
                      }}
                    </h3>
                  </div>
                  <!-- Legacy Code; usefulness undetermined -->
                  <!-- <div class="xcell xamount" style="display: none">
                    <h6>${{ application.amount }}</h6>
                  </div>
                  <div class="xcell xowner" style="display: none">
                    <div
                      class="xusericon xtooltip"
                      data-size="tiny"
                      style="display: inline-block; vertical-align: middle">
                      <span class="xtooltiptext">Jennifer Pao</span>
                      <div class="xusericon-picture" style="color: white">
                        JP
                      </div>
                    </div>
                    <i class="icon-down-open-big" style="font-size: 10px"></i>
                  </div> -->
                </router-link>
              </div>
              <!-- Search Results None -->
              <div v-if="applications.data.length === 0" class="xfoundnothing">
                <h2 class="no-match-style">
                  No matches found
                </h2>
                <b>0</b> results
                <span v-if="applications.search.active"
                  >for <b>"{{ applications.search.text }}"</b></span
                >
                <span v-if="selectedFilters('text').length > 0"
                  >with the filters</span
                >
                <b
                  ><span>{{ selectedFilters("text") }}</span></b
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  </div>
</template>

<script setup>
  import { reactive, onMounted, watch, defineProps } from "vue";
  import Page from "@/components/layout/Page.vue";
  import Loader from "@/components/Loader.vue";
  import store from "@/store";
  import { useRouter, useRoute } from "vue-router";
  import functions from "@/use/functions.js";
  import useFunctions from "@/use/functions1";
  import { isAdminUser } from "@/use/useIsCreditUnionUser";
  import Pagination from "@/components/search-page/Pagination.vue";
  import SearchFilter from "@/components/search-page/SearchFilter.vue";
  import {useApplicationStore} from "@/store/store/applicationVMStore";

  
  //////////////
  // vue data //
  //////////////
  const { decodeHTMLEncodedStr } = useFunctions();

  const applicationStore = useApplicationStore();

  const maxFilterResults = 8;

  const props = defineProps(["filters", "pageName"]);
  var loading = $ref(true);
  var route = useRoute();
  var router = useRouter();
  const applications = reactive({
    data: [],
    dataDropdown: [],
    dropdownData: false,
    filterDisplay: false,
    filterOrder: 0,
    filters: props.filters,
    layout: "boxes",
    modal: false,
    recentlyViewed: false,
    search: {
      text: "",
      type: "",
      typing: "",
      timestamp: 0,
      active: false,
      focus: false,
      error: false,
      dropdown: false,
    },
    query: {
      pageNumber: false,
      pageTotal: 0,
      totalCount: 0,
    },
  });



  /////////////////
  // vue methods //
  /////////////////

  async function triggerSearch(searchText, pageNumber) {
    /////////////////////////////////////////////////////////////////////////////////////////
    // description: adds search parameters to the page url to trigger searchApplications() //
    /////////////////////////////////////////////////////////////////////////////////////////

    if (searchText) {
      searchText = functions.cleanSearchText(searchText);
      applications.search.typing = searchText;
      applications.search.text = searchText;
      applications.search.active = true;
    } else if (applications.search.active && applications.search.text) {
      searchText = functions.cleanSearchText(applications.search.text);
      applications.search.typing = searchText;
      applications.search.text = searchText;
    }

    if (pageNumber) {
      applications.query.pageNumber = pageNumber;
    }

    // if the user is searching by ssn
    if (functions.isValidSSN(searchText)) {
      searchApplications(searchText);
    } else {
      var filters = JSON.parse(JSON.stringify(applications.filters));

      var response = functions.getSearchParameters(
        searchText,
        filters,
        applications.query.pageNumber
      );

      if (response) {
        // convert the object to url parameters
        var urlParameters = functions.objectToUrlString(response.parameters);

        // redirect to the search url to perform the search
        router.push("?" + urlParameters);
      } else {
        functions.openModal("Error Getting Search Parameters");
        console.log("Error: triggerSearch()->getSearchParameters()");
      }
    }
  }

  async function searchApplications() {
    ///////////////////////////////////////////////////////////////////////////////////
    // description: searches applications/loans and displays the results on the page //
    ///////////////////////////////////////////////////////////////////////////////////

    applications.data = "Loading...";

    try {
      let response;

      response = await functions[`get${props.pageName}`](
        applications.search.text,
        applications.filters,
        applications.query.pageNumber,
      );

      if (response.data) {
        applications.data =
          props.pageName === "Loans" ? response.data.items : response.data.data;

        applications.query = {
          pageNumber: response.data.pageNumber || response.data.page.index,
          pageTotal: Math.ceil(response.data.totalCount / 30),
          totalCount: response.data.totalCount,
        };
      } else {
        // no matches found
        applications.data = [];
        applications.query = {
          pageNumber: false,
          pageTotal: 0,
          totalCount: 0,
        };
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      applications.data = "Error Loading Results";
    }
  }

  async function searchDropdown(searchText) {
    ///////////////////////////////////////////////////////////////////////////////////////////////
    // description: displays search results in a dropdown as the user is typing in the searchbox //
    ///////////////////////////////////////////////////////////////////////////////////////////////

    // clean the search text
    searchText = functions.cleanSearchText(searchText);
    applications.search.typing = searchText;
    applications.search.timestamp = Date.now();

    await functions[`get${props.pageName}`](searchText)
      .then((response) => {
        if (
          response &&
          response.config.postTime >= applications.search.timestamp
        ) {
          if (props.pageName == "Loans") {
            applications.dataDropdown = response.data.items;
          } else {
            applications.dataDropdown = response.data.data;
          }
        }
      })
      .catch((error) => {
        applications.dataDropdown = false;
        console.log(error);
      });
  }

  function checkFilterParam(filters, param, name) {
      const filter = filters.find((f) => f.param === param);
      const result = filter?.results.find((f) => f.name === name);

      if (result) {
        result.checked = true;
      }
  }

  async function getParametersFromUrl() {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // description: checks which parameters are present in the page url and selects filters based on those parameters //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // clear all existing filters on the page
    clearAllFilters();

    if (props.pageName == "Applications") {
      checkFilterParam(applications.filters, "ProductIds", "All");
    } else if (props.pageName == "Loans") {
      checkFilterParam(applications.filters, "ProductTypeIds", "All");
    }

    // if the current page url has parameters
    if (Object.keys(route.query).length > 0) {
      // for each of the url parameters
      Object.entries(route.query).forEach(([key, value]) => {
        // if the parameter value is an array when parsed
        if (Array.isArray(JSON.parse(value))) {
          var values = JSON.parse(value);

          if (key == "SearchTerms") {
            applications.search.active = true;
            applications.search.text = values.join(" ");
            applications.search.typing = values.join(" ");
          } else {
            // for each of the values in the array
            values.forEach((value) => {
              var selectedFilter = applications.filters.find(
                (f) => f.param == key
              );

              if (selectedFilter) {
                selectedFilter.results.forEach((result) => {
                  if (selectedFilter.type == "radio") {
                    // for each of the radio buttons
                    selectedFilter.results.forEach((item) => {
                      // uncheck all the radio buttons
                      item.checked = false;
                    });

                    // set checked to true
                    selectedFilter.results.filter(
                      (f) => f.id == value
                    )[0].checked = true;

                    // if the result has children
                  } else if (result.children) {
                    result.children.forEach((child) => {
                      if (Number(child.id) === Number(value)) {
                        // set the child checked to true
                        child.checked = true;
                      }
                    });

                    // set the result to "checked" if every child is checked (the .every() method returns true or false)
                    result.checked = result.children.every(
                      (child) => child.checked
                    );
                  } else if (Number(result.id) === Number(value)) {
                    result.checked = true;
                  }
                });
              }
            });
          }

          // if the parameter value is a string when parsed
        } else {
          if (key == "SearchTerm") {
            applications.search.active = true;
            applications.search.text = value;
            applications.search.typing = value;
          } else if (key == "PageNumber") {
            applications.query.pageNumber = Number(value);
          }
        }
      });
    }
  }

  async function setLenderFilters() {
    //////////////////////////////////////////////////////////////////////////////////
    // description: gets the lenders and sets them as filter options on the sidebar //
    //////////////////////////////////////////////////////////////////////////////////

    // if the user has admin access to see all lenders
    if (store.state.oidcStore.user.los_access == "Yes") {
      // check if the lenders are not already loaded
      if (!applications.filters.find((filter) => filter.param == "LenderIds")) {
        // get the lenders
        await functions
          .getLenders()
          .then(() => {
            var lenderData = JSON.parse(
              JSON.stringify(store.state.types["Lenders"])
            );

            lenderData.forEach((item) => {
              // add a checked field
              item.checked = false;
            });

            // sort the lenders by name
            lenderData.sort((a, b) => a.name.localeCompare(b.name));

            // insert the data into applications.filters
            applications.filters.push({
              description: "Credit Union",
              param: "LenderIds",
              results: lenderData,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  function selectFilter(event, input, parent) {
    ////////////////////////////////////////////////////
    // description: executes when a filter is clicked //
    ////////////////////////////////////////////////////

    if (parent?.type == "radio") {
      // for each of the radio buttons
      parent.results.forEach((item) => {
        // uncheck all the radio buttons
        item.checked = false;
      });

      // check the radio button which was just clicked
      input.checked = true;
    } else {
      // update the value on the clicked checkbox
      input.checked = event.target.checked;

      input.filterOrder = applications.filterOrder++;

      // if the filter has children
      if (input.children) {
        // loop through each of the children of the parent
        input.children.forEach((item) => {
          item.filterOrder = applications.filterOrder++;

          // set the child to match the parent
          item.checked = event.target.checked;
        });
      }

      // if the filter has a parent
      if (parent) {
        // set the parent to "checked" if every child is checked (the .every() method returns true or false)
        parent.checked = parent.children.every((child) => child.checked);
      }
    }

    triggerSearch(applications.search.text, 1);
  }

  function clearSearchbox() {
    ///////////////////////////////////////////////////////////////
    // description: clears the search box and deactivates search //
    ///////////////////////////////////////////////////////////////

    // reset the search text
    applications.search.text = "";
    applications.search.typing = "";

    // if the search is active (and not just a dropdown)
    if (applications.search.active) {
      // deactivate the search
      applications.search.active = false;
    }
  }

  function clearFilter(item) {
    ////////////////////////////////////////////////////////
    // description: clears a single filter on the sidebar //
    ////////////////////////////////////////////////////////

    // uncheck the item
    item.checked = false;

    // if the item has children
    if (item.children) {
      // for each of the children
      item.children.forEach((child) => {
        // uncheck all the child fields
        child.checked = false;
      });
    }

    triggerSearch(applications.search.text, 1);
  }

  function clearAllFiltersAndSearch() {
    ///////////////////////////////////////////////////////////////////////////////////////
    // description: redirects to the search page without any query parameters in the url //
    ///////////////////////////////////////////////////////////////////////////////////////

    router.push("");
  }

  function clearAllFilters() {
    ////////////////////////////////////////////////////////
    // description: clears all the filters on the sidebar //
    ////////////////////////////////////////////////////////

    // for each filter section
    applications.filters.forEach((section) => {
      // for each of the fields inside that filter section
      section.results.forEach((field) => {
        // uncheck all the fields
        field.checked = false;

        // for each of the children
        field.children?.forEach((child) => {
          // uncheck the child fields
          child.checked = false;
        });
      });
    });

    clearSearchbox();
  }

  function selectedFilters(output) {
    ///////////////////////////////////////////////////////
    // description: returns an array of selected filters //
    ///////////////////////////////////////////////////////

    var data = [];

    // for each filter section that is not a radio button
    applications.filters
      .filter((section) => section.type != "radio")
      .forEach((section) => {
        // for each of the fields inside that filter section
        section.results.forEach((field) => {
          // if the field has children and is not checked
          if (field.children && !field.checked) {
            // for each of the children
            field.children.forEach((child) => {
              // if the child is checked
              if (child.checked) {
                // add the section to the object
                child["section"] = section.description;

                // push the child to the data array
                data.push(child);
              }
            });

            // else if the field is checked
          } else if (field.checked) {
            // add the section to the object
            field["section"] = section.description;

            // push the field to the data array
            data.push(field);
          }

          if (field.children) {
            // set the field to "checked" if every child is checked (the .every() method returns true or false)
            field.checked = field.children.every((child) => child.checked);
          }
        });
      });

    // sort the filter buttons by the order they were clicked
    data.sort((a, b) => {
      return a.filterOrder - b.filterOrder;
    });

    // option to output the array as text
    if (output == "text") {
      // convert the array to a string to return selected filters as text
      data = data
        .map((field) => {
          return `"${field.name}"`;
        })
        .join(" or ");
    }

    return data;
  }

  function blurSearchbox() {
    // waits 0.2 seconds before closing the searchbox so the dropdown click works without issues
    setTimeout(() => {
      applications.search.focus = false;
    }, 200);
  }

  function filteredSectionResults(section) {
    return section.results.filter((item) => {
      const itemNameMatches = item.name
        ?.toLowerCase()
        .includes(section.search?.toLowerCase());
      const isVisible =
        !item.internalOnly || (isAdminUser() && item.internalOnly === true);

      return isVisible && (!section.search || itemNameMatches);
    });
  }

  function sectionResultsExceedMaxVisible(section) {
    return section?.results?.length > maxFilterResults;
  }

  function handleKeyup(event) {
    if (event.key === "Enter") {
      return;
    }
    if (applications.search.typing.length >= 3) {
      searchDropdown(applications.search.typing);
    } else {
      applications.dataDropdown = [];
    }
  }

  function setSelectedApplication(application)
  {
      applicationStore.applicationMetadata = {
        referenceId: application.referenceId,
        applicationId: application.applicationId,
        lenderId: application.lenderId,
        productTypeId: application.productId,
        programTypeId: application.programId
      };
  }

  ///////////////////////////////////////////////////////////////////
  // vue watch - executes each time the page url parameters change //
  ///////////////////////////////////////////////////////////////////

  watch(
    () => route.params,
    async () => {
      await getParametersFromUrl();
      await searchApplications();
    }
  );

  /////////////////////////////////////////////////////////////////
  // vue onMounted - executes when the page is finished mounting //
  /////////////////////////////////////////////////////////////////

  onMounted(async () => {
    await setLenderFilters();
    await getParametersFromUrl();
    await searchApplications();

    loading = false;

    // if the localstore has any recently viewed applications
    if (localStorage.getItem("cache.RecentlyViewedPages")) {
      // retreive the data from localstorage
      applications.recentlyViewed = JSON.parse(
        localStorage.getItem("cache.RecentlyViewedPages")
      ).filter((item) => item.page == props.pageName.toLowerCase());
    }
  });
</script>

<style scoped>
  .button-style {
      width: 40px;
    }
  .pagination-area-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .no-match-style {
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .pagination-component {
    display: flex;
    align-items: center;
    padding: 12px 0px;
  }
  .search-error {
    display: inline-block;
    margin-left: 10px;
  }
  .width-100 {
    width: 100%;
  }
  .xpage-content-page {
    padding-left: 20px;
  }
  
  .xfoundnothing h2 {
    padding-top: 5px;
    margin-bottom: 10px;
  }

  .search-error {
    display: inline-block;
    margin-left: 10px;
  }

  .mobile-menu-button {
    width: 40px;
  }
  
  .onehundred-percent-width {
    width: 100%;
  }
  </style>
