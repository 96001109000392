import { StipulationStrategy, StipulationType } from "@/types/stipulation";
import { PayoffLoans } from '@/types/stipulation';
import { StipulationTypes } from "@/types/types";

export class RefiLoanAccountNumberStrategy implements StipulationStrategy {
    id = 0;
    defaultText = "";

    setDefaultText(defaultText: string) {
        if (defaultText !== undefined && defaultText) {
            this.defaultText = defaultText;
        }
    }

    mergeFields(refiLoan?: PayoffLoans, additionalParams?: Record<string, any>): string {
        if (refiLoan !== undefined) {
            return this.defaultText
                .replaceAll('{{AccountNumber}}', refiLoan.accountNumberLastDigits);
        }

        return "";
    }

    isApplicable(stipulationType: StipulationTypes): boolean {
        return stipulationType == StipulationTypes.ResponsibilityForUnderPayment ||
        stipulationType == StipulationTypes.ServicerPayoffAddress;
    }
}